import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import initializeAxios from 'utils/axios';

// material-ui
import { Typography, IconButton, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CustomTable from 'component/CustomTable';
// project import
import Breadcrumb from 'component/Breadcrumb';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LoadingOverlay from 'component/LoadingOverlay';
import AlertDialogSlide from 'views/Elements/Advance/UIDialog/AlertDialogSlide';

const AircraftQuote = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const axios = initializeAxios(auth);

  const columns = [
    {
      id: 'customerOrganization', 
      label: 'Named Insured'
    },
    {
      id: 'quoteUserProfile',
      label: 'Company User'
    },
    {
      id: 'quoteStatus',
      label: 'Status'
    }
  ];

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [quoteId, setQuoteId] = useState(null);
  const { companyId } = useParams();
  const handleClickOpendialog = (quote) => {
    setQuoteId(quote.id);
    setOpen(true);
  };
  const handleClosedialog = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const quote = await axios.post(`/api/quotes/companyId/${companyId}`, {
      policyType: 27
    });
    navigate(`/company/${companyId}/workflow/aircraft-quote/quote/${quote.data.id}`);
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const response = await axios.delete(`/api/quotes/${quoteId}/companyId/${companyId}`);
    const tempArray = [...quotes];
    const index = tempArray.findIndex((e) => e.id === response.data.id);
    tempArray.splice(index, 1);
    setQuotes(tempArray);
    setIsLoading(false);
  };

  const handleEdit = (quote) => {
    navigate(`/company/${companyId}/workflow/aircraft-quote/quote/${quote.id}`);
  };

  useEffect(() => {
    const getAircraftQuotes = async () => {
      setIsLoading(true);
      const result = await axios.get(`/api/quotes/companyId/${companyId}`, {
        params: {
          idPolicyType: 27
        }
      });
      setQuotes(result.data);
      setIsLoading(false);
    };

    getAircraftQuotes();
  }, [companyId]);

  return (
    <>
      <Breadcrumb title="Aircraft Quote" sx={{ mt: { lg: 0, xs: 2 } }}>
        <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Aircraft Quote
        </Typography>
      </Breadcrumb>
      <LoadingOverlay loading={isLoading} />
      <AlertDialogSlide
        message="Confirm if you want to delete this quote. By doing this will archive the quote and you will lose progress on this quote."
        handleCloseDialog={handleClosedialog}
        handleResult={handleDelete}
        emitValue={quoteId}
        open={open}
      />
      <CustomTable
        title="Quotes"
        isLoading={isLoading}
        data={quotes}
        columns={columns}
        hasPagination={false}
        hasCreateButton={true}
        handleOpenCreate={handleCreate}
      >
        {(row) => (
          <>
            <Tooltip title="Edit Quote" placement="top">
              <span>
                <IconButton
                  color="primary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Quote')}
                  onClick={() => handleEdit(row)}
                >
                  <EditTwoToneIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete Quote" placement="top">
              <span>
                <IconButton
                  color="secondary"
                  aria-label="Edit"
                  size="large"
                  disabled={!auth.writePrivledge(auth.user, 'Quote')}
                  onClick={() => handleClickOpendialog(row)}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </CustomTable>
    </>
  );
};

export default AircraftQuote;
