import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment
} from '@mui/material';

// Project Imports
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// Local Imports
import { formatDate } from 'utils/FormatUtils';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { EndDateSelections } from 'utils/endDateSelection';

// Icons
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import { gridSpacing } from 'config.js';

import React, { useEffect, useState } from 'react';

const AircraftQuotePolicy = ({
  companyId,
  selectedCustomerOrg,
  selectedAgency,
  selectedAgent,
  selectedContact,
  selectedAirport = {},
  emitSelectedPolicy,
  handleNext,
  activeStep,
  isAirport = false
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [policy, setPolicy] = useState({ clientNameInsured: selectedCustomerOrg.name, idCompanyUser: auth?.user?.id });
  const [insurances, setInsurances] = useState([]);
  const [users, setUsers] = useState([]);
  const [endDateSelection, setEndDateSelection] = useState(-1);

  const handlePolicySave = async () => {
    let airportInfo = {};

    if (isAirport && selectedAirport) {
      airportInfo = {
        idAirport: selectedAirport?.id,
        airportHullAmount: policy.airportHullAmount,
        airportHullCode: policy.airportHullCode,
        airportLiabilityAmount: policy.airportLiabilityAmount,
        airportLiabilityCode: policy.airportLiabilityCode,
        airportMedicalAmount: policy.airportMedicalAmount,
        airportMedicalCode: policy.airportMedicalCode,
        airportUseAmount: policy.airportUseAmount,
        airportUseCode: policy.airportUseCode,
        airportTerritoryAmount: policy.airportTerritoryAmount,
        airportTerritoryCode: policy.airportTerritoryCode,
        airportWarTriaAmount: policy.airportWarTriaAmount,
        airportWarTriaCode: policy.airportWarTriaCode
      };
    }

    const response = await axios.post(`/api/policy/companyId/${companyId}`, {
      policy: {
        idCustomerOrganization: selectedCustomerOrg?.id,
        idAgent: selectedAgent?.id,
        idCompanyUser: policy?.idCompanyUser,
        idAgency: selectedAgency.id,
        clientNameInsured: policy.clientNameInsured,
        endorsements: policy?.endorsements || '',
        beginDate: policy?.beginDate || '',
        endDate: policy?.endDate || '',
        idPolicyType: isAirport ? 28 : 27,
        policyNumber: policy?.policyNumber || '',
        idPolicyStatus: 22,
        trainingRequired: policy.trainingRequired,
        idParentPolicy: policy?.idParentPolicy || null,
        idInsurance: policy?.idInsurance || null,
        insuranceNotes: policy?.insuranceNotes || '',
        idCustomerContact: selectedContact?.id || undefined,
        ...airportInfo
      }
    });
    emitSelectedPolicy(activeStep, response.data);
    if (!isAirport) {
      handleNext();
    }
  };
  const handleBeginDateChange = (date) => {
    let newDate;
    if(date){
      newDate = formatDate(date);
    } else {
      newDate = null;
    }

    setPolicy({
      ...policy,
      beginDate: newDate
    });
  };

  const handleEndDateChange = (date) => {
    let newDate;
    if(date){
      newDate = formatDate(date);
    } else {
      newDate = null;
    }

    setPolicy({
      ...policy,
      endDate: newDate
    });
  };

  const handleEndDateSelection = (event) => {
    const { value } = event.target;
    setEndDateSelection(value);
    const beginDate = formatDate(policy.beginDate);
    const endDate = beginDate.clone();
    if (value !== 0 && value !== -1) {
      let newEndDate;
      if (value !== 12) {
        newEndDate = endDate.add(value, 'month');
      } else {
        newEndDate = endDate.add(1, 'year');
      }

      console.log(newEndDate);

      setPolicy({
        ...policy,
        endDate: newEndDate
      });
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setPolicy({
      ...policy,
      [name]: name.includes('training') ? (value === 'true' ? true : false) : value
    });
  };

  useEffect(() => {
    const getInsurances = async () => {
      const result = await axios.get(`/api/insurance/companyId/${companyId}`);
      setInsurances([...result.data]);
    };
    const getCompanyUserProfiles = async () => {
      const result = await axios.get(`/api/company/${companyId}/users`);
      setUsers(result.data.UserProfile);
    };

    getInsurances();
    getCompanyUserProfiles();
  }, [companyId]);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item sm zeroMinWidth>
              <Typography component="div" variant="h5">
                Client Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <Typography variant="subtitle2">
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Client Name Insured"
                      name="clientNameInsured"
                      variant="outlined"
                      onChange={handleChange}
                      value={policy?.clientNameInsured || ''}
                      error={!policy?.clientNameInsured}
                      helperText={!policy?.clientNameInsured ? 'Cannot Be Empty' : ''}
                      InputProps={{
                        readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                      }}
                    />
                  </Typography>
                </Grid>
                {users.length > 0 && (
                  <Grid item lg={6} xs={12}>
                    <Typography variant="subtitle2">
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={policy?.idCompanyUser || ''}
                        onChange={handleChange}
                        name="idCompanyUser"
                        label="Company User"
                        error={!policy?.idCompanyUser}
                        helperText={!policy?.idCompanyUser && 'Required'}
                        InputProps={{
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                        }}
                      >
                        {users.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.displayName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="div" variant="h5">
                    Insurance Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div" variant="subtitle1">
                    Insurance : &nbsp;
                    <Typography component="span" variant="subtitle2">
                      <TextField fullWidth select value={policy?.idInsurance || ''} name="idInsurance" onChange={handleChange}>
                        {insurances.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} />
            <Grid item sm={4} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="div" variant="subtitle1">
                    Insurance Notes : &nbsp;
                    <Typography component="span" variant="subtitle2">
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        name="insuranceNotes"
                        multiline
                        rows={6}
                        value={policy?.insuranceNotes || ''}
                        variant="outlined"
                        InputProps={{
                          readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                        }}
                        onChange={handleChange}
                      />
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="div" variant="h5">
                    Policy Info
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography component="div" variant="subtitle1">
                        Policy Number : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            sx={{ mt: 1 }}
                            fullWidth
                            name="policyNumber"
                            variant="outlined"
                            value={policy?.policyNumber || ''}
                            error={!policy?.policyNumber}
                            helperText={!policy?.policyNumber ? 'Cannot Be Empty' : ''}
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography component="div" variant="h6">
                        Policy Date Range
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="div" variant="subtitle1">
                        Begin Date : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <DateTimePicker
                            value={policy?.beginDate ? formatDate(policy.beginDate) : null}
                            onChange={handleBeginDateChange}
                            sx={{ width: '100%' }}
                            slotProps={{
                              textField: {
                                error: !policy?.beginDate,
                                helperText: !policy?.beginDate ? 'Begin Date is Required' : '',
                                fullWidth: true
                              }
                            }}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="div" variant="subtitle1">
                        End Date Selection : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            fullWidth
                            select
                            value={endDateSelection}
                            onChange={handleEndDateSelection}
                            disabled={!policy.beginDate}
                            error={endDateSelection === -1}
                            helperText={endDateSelection === -1 ? 'Please assign a Begin date' : ''}
                          >
                            {EndDateSelections.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="div" variant="subtitle1">
                        End Date : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <DateTimePicker
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            sx={{ width: '100%' }}
                            value={policy?.endDate ? formatDate(policy.endDate) : null}
                            disabled={endDateSelection !== 0}
                            slotProps={{
                              textField: {
                                error: !policy?.endDate,
                                helperText: !policy?.endDate ? 'Please Assign a end date for policy' : '',
                                fullWidth: true
                              }
                            }}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            keyboardIcon={<EventTwoToneIcon />}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="div" variant="h5" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography component="div" variant="subtitle1">
                        Endorsements : &nbsp;
                        <Typography component="span" variant="subtitle2">
                          <TextField
                            sx={{ mt: 3 }}
                            fullWidth
                            name="endorsements"
                            multiline
                            rows={6}
                            value={policy?.endorsements || ''}
                            variant="outlined"
                            InputProps={{
                              readOnly: !auth.writePrivledge(auth.user, 'Policy_Adm')
                            }}
                            onChange={handleChange}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={gridSpacing}>
            <Grid item sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="div" variant="h5">
                    Training Required
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="trainingRequired"
                          value={policy?.trainingRequired || false}
                          onChange={handleChange}
                        >
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                            </Grid>
                            <Grid item>
                              <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {isAirport && (
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item sm zeroMinWidth>
                <Typography component="div" variant="h5">
                  Policy Options Airport
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportLiabilityCode || ''}
                      label="Airport Liability"
                      name="airportLiabilityCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Liability Amount"
                      id="filled-start-adornment1"
                      name="airportLiabilityAmount"
                      value={policy.airportLiabilityAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportHullCode || ''}
                      label="Airport Hull"
                      name="airportHullCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Hull Amount"
                      id="filled-start-adornment2"
                      name="airportHullAmount"
                      value={policy.airportHullAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportMedicalCode || ''}
                      label="Airport Medical"
                      name="airportMedicalCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Medical Amount"
                      id="filled-start-adornment3"
                      name="airportMedicalAmount"
                      value={policy.airportMedicalAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportTerritoryCode || ''}
                      label="Airport Territory"
                      name="airportTerritoryCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Territory Amount"
                      id="filled-start-adornment4"
                      name="airportTerritoryAmount"
                      value={policy.airportTerritoryAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportUseCode || ''}
                      label="Aircraft Use"
                      name="airportUseCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="Use Amount"
                      id="filled-start-adornment5"
                      name="airportUseAmount"
                      value={policy.airportUseAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ mt: 1 }}
                      value={policy.airportWarTriaCode || ''}
                      label="Airport WarTria"
                      name="airportWarTriaCode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mt: 1 }}
                      label="WarTria Amount"
                      id="filled-start-adornment5"
                      name="airportWarTriaAmount"
                      value={policy.airportWarTriaAmount || ''}
                      type="number"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item sm={6} md={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePolicySave}
                    disabled={
                      !policy?.clientNameInsured ||
                      !policy?.policyNumber ||
                      !policy?.endDate ||
                      !policy?.beginDate ||
                      !auth.writePrivledge(auth.user, 'Policy_Adm')
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AircraftQuotePolicy;
