import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { FooterPDF } from 'views/PDFComponents/Footer';

const styles = StyleSheet.create({
  header: {
    fontSize: 9,
    textAlign: 'center',
    marginBottom: 10
  },
  divider: {
    borderTop: '3px solid black'
  },

  contentDivider: {
    borderTop: '0.5px solid black'
  },
  content: {
    fontSize: 8,
    paddingBottom: 200
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    fontSize: 12,
    lineHeight: 1.5,
    position: 'relative'
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  textContent: {
    fontFamily: 'OpenSans'
  },

  listContainer: {
    paddingLeft: 10,
    marginVertical: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '10px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },

  nestedListContainer: {
    paddingLeft: 15 // Indent nested items
  },
  nestedListItemParagraph: {
    top: '-12px'
  },
  dataInfo: {
    color: 'red'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const boldWords = ['aircraft', 'FAA'];

const PilotRequirementPDF = ({ requirements, rental = false }) => (
  <>
    <Page size="letter" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Requirements for the Pilot Flying the Aircraft</Text>
      </View>
      <View style={[styles.content, { marginBottom: '-100px' }]}>
        <View style={{ marginBottom: 10 }}>
          <Text>
            This endorsement applies only to the following <Text style={styles.boldText}>aircraft:</Text>
          </Text>
        </View>
        <View style={{ textAlign: 'center', fontStyle: 'italic' }}>
          <Text>
            If no entry is made this endorsement applies to all <Text style={styles.boldText}>aircraft</Text> covered by{' '}
            <Text style={styles.boldText}>your</Text> policy
          </Text>
        </View>
        <View style={{ marginTop: 10 }}>
          <Text>
            This endorsement completes or changes Item 9.{' '}
            <Text style={styles.boldText}>REQUIREMENTS FOR THE PILOT FLYING THE AIRCRAFT</Text> of <Text style={styles.boldText}>your</Text>{' '}
            Coverage Identification Page to read as follows:
          </Text>
        </View>
        <View style={{ marginTop: 20 }}>
          <Text>
            The <Text style={styles.boldText}>aircraft</Text> must be operated <Text style={styles.boldText}>in flight</Text> only by a
            pilot named below having the minimum qualifications shown. The pilot must have a current and valid (1) medical certificate, (2)
            flight review and (3) pilot certificate with necessary ratings, each as required by the <Text style={styles.boldText}>FAA</Text>{' '}
            for each flight. There is no coverage if the pilot does not meet the qualifications or requirements specified below for each
            designated use of the <Text style={styles.boldText}>aircraft:</Text>
          </Text>
        </View>
        {rental ? (
          <>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.boldText}>Important Provisions Applicable to Any Aircraft Approved for Instruction and Rental</Text>
            </View>
            <View style={styles.listContainer}>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeading}>A. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
                  <Text>
                    All <Text style={styles.boldText}>aircraft</Text> approved for instruction and rental may be used only for the{' '}
                    <Text style={styles.boldText}>renter pilot`s</Text> personal <Text style={styles.boldText}>pleasure and business</Text>
                    purposes. No coverage is provided by <Text style={styles.boldText}>your</Text> policy if a{' '}
                    <Text style={styles.boldText}>renter pilot</Text> makes a charge to anyone for the use of the{' '}
                    <Text style={styles.boldText}>aircraft.</Text>
                  </Text>
                </View>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeading}>B. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
                  <Text>
                    All flight instruction in the <Text style={styles.boldText}>aircraft</Text> must be given by a properly certificated and
                    rated flight instructor named or having the minimum flight experience shown in the “FLIGHT INSTRUCTORS” section of this
                    endorsement for the particular category or class of aircraft. Any flight instructor approved to operate the{' '}
                    <Text style={styles.boldText}>aircraft in flight</Text> for instruction may also operate the{' '}
                    <Text style={styles.boldText}>aircraft</Text> for <Text style={styles.boldText}>pleasure and business</Text> purposes.
                  </Text>
                </View>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeading}>C. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
                  <Text>
                    All <Text style={styles.boldText}>renter pilots</Text> must receive a flight checkout from a properly certificated and
                    rated flight instructor named or having the minimum flight experience shown in the “FLIGHT INSTRUCTORS” section of this
                    endorsement for the particular category or class of aircraft prior to operating the{' '}
                    <Text style={styles.boldText}>aircraft</Text> solo as pilot in command.
                  </Text>
                </View>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeading}>D. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
                  <Text>
                    Any person holding at least a student pilot certificate may receive flight instruction in any single engine land
                    aircraft not requiring a complex <Text style={styles.boldText}>aircraft</Text> endorsement for pilot in command
                    operation (or otherwise specifically approved for Student instruction by this endorsement) while accompanied by a
                    properly certificated and rated flight instructor named or having the minimum flight experience shown in the ‘FLIGHT
                    INSTRUCTORS” section of this endorsement for the particular category or class of{' '}
                    <Text style={styles.boldText}>aircraft,</Text> or while taking a flight test with a FAA examiner or designated examiner
                    for a pilot certificate or rating.
                  </Text>
                </View>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeading}>E. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
                  <Text>
                    Any person holding at least a private pilot certificate may receive flight instruction in any single engine or multi-
                    engine land <Text style={styles.boldText}>aircraft</Text> while accompanied by a properly certificated and rated flight
                    instructor named or having the minimum flight experience shown in the “FLIGHT INSTRUCTORS” section of this endorsement
                    for the particular category or class of <Text style={styles.boldText}>aircraft,</Text> or while taking a flight test
                    with a FAA examiner or designated examiner for a pilot certificate or rating.
                  </Text>
                </View>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.listItemHeading}>F. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.listItemParagraph, { marginTop: '-16px', marginBottom: '-40px' }]}>
                  <Text>
                    <Text style={styles.boldText}>ALL FLIGHTS BY A STUDENT PILOT</Text> in any <Text style={styles.boldText}>aircraft</Text>{' '}
                    approved for student instruction must be under the direct supervision of and specifically approved by a properly
                    certificated and rated flight instructor named or having the minimum flight experience shown in the “FLIGHT INSTRUCTORS”
                    section of this endorsement for the particular category or class of
                    <Text style={styles.boldText}>aircraft.</Text>
                  </Text>
                </View>
              </View>
            </View>
          </>
        ) : (
          <>
            <View style={{ marginTop: 20, marginBottom: 5 }}>
              <Text style={styles.boldText}>MINIMUM REQUIREMENTS FOR PILOT, PILOT CERTIFICATE, RATINGS AND LOGGED FLYING HOURS:</Text>
            </View>
            <View style={[styles.contentDivider, { marginBottom: 10 }]} />
            <View style={{ marginBottom: 10 }}>
              <Text style={styles.dataInfo}>
                {requirements?.split(' ').map((word, index) => (
                  <Text key={index} style={boldWords.includes(word) ? styles.boldText : undefined}>
                    {word}{' '}
                  </Text>
                ))}
              </Text>
            </View>
          </>
        )}
      </View>
      <FooterPDF page={1} endPage={rental ? 2 : 1} />
    </Page>
    {rental && (
      <Page size="LETTER" style={styles.page}>
        <View style={styles.content}>
          <View style={{ marginTop: 20, marginBottom: 5 }}>
            <Text style={styles.boldText}>MINIMUM REQUIREMENTS FOR PILOT, PILOT CERTIFICATE, RATINGS AND LOGGED FLYING HOURS:</Text>
          </View>
          <View style={[styles.contentDivider, { marginBottom: 10 }]} />
          <View style={{ marginBottom: 10 }}>
            <Text style={styles.dataInfo}>
              {requirements?.split(' ').map((word, index) => (
                <Text key={index} style={boldWords.includes(word) ? styles.boldText : undefined}>
                  {word}{' '}
                </Text>
              ))}
            </Text>
          </View>
        </View>
        <FooterPDF sub={true} page={2} endPage={2} />
      </Page>
    )}
  </>
);

export default PilotRequirementPDF;
