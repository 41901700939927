import ArizonaEndorsement from './Arizona';
import ArkansasEndorsement from './Arkansas';
import ColoradoEndorsement from './Colorado';
import FloridaEndorsement from './Florida';
import GeorgiaEndorsement from './Georgia';
import IdahoEndorsement from './Idaho';
import IllinoisEndorsement from './Illinois';

const statesEndorsements = [
  { state: 'Arizona', element: (props) => <ArizonaEndorsement {...props} /> },
  { state: 'Arkansas', element: (props) => <ArkansasEndorsement {...props} /> },
  { state: 'Colorado', element: (props) => <ColoradoEndorsement {...props} /> },
  { state: 'Florida', element: (props) => <FloridaEndorsement {...props} /> },
  { state: 'Georgia', element: (props) => <GeorgiaEndorsement {...props} /> },
  { state: 'Idaho', element: (props) => <IdahoEndorsement {...props} /> },
  { state: 'Illinois', element: (props) => <IllinoisEndorsement {...props} /> }
];

const StateEndorsement = ({ state, formNumber }) => {
  const endorsement = statesEndorsements.find((item) => item.state === state);
  return endorsement ? endorsement.element(formNumber) : null;
};

export default StateEndorsement;
