/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
// material-ui
import {
  Grid,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// project import
import { gridSpacing } from 'config.js';

// icons
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import InputAdornment from '@mui/material/InputAdornment';

// Local imports
import { formatDate } from 'utils/FormatUtils';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import { EndDateSelections } from 'utils/endDateSelection';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FormControlCustom = styled((props) => <FormControl {...props} />)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 160,
  maxWidth: 500,
  width: '100%'
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const CustomDialogTitle = styled((props) => <DialogTitle {...props} />)(() => ({
  padding: 0
}));

// eslint-disable-next-line react/prop-types
const PolicyDialog = ({
  open,
  handleClosedialog,
  handlePolicySave,
  actionType,
  policy,
  customerOrg,
  users,
  companyId,
  customerOrgId = null,
  isPolicyPilot = false,
  isPolicyAircraft = false,
  policyRelations = []
}) => {
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const [newPolicy, setNewPolicy] = useState({});
  const [endDateSelection, setEndDateSelection] = useState(-1);
  const [policyStatus, setPolicyStatus] = useState([]);
  const [policyTypes, setPolicyTypes] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [airports, setAirports] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const [filterCustomerOrgId, setFilterCustomerOrgId] = useState(null);

  const [aircraftLiabilities, setAircraftLiabilities] = useState([]);
  const [aircraftHulls, setAircraftHulls] = useState([]);
  const [aircraftMedicals, setAircraftMedicals] = useState([]);
  const [aircraftTerritories, setAircraftTerritories] = useState([]);
  // const [aircraftUses, setAircraftUses] = useState([]);
  const [aircraftWarTrias, setAircraftWarTrias] = useState([]);

  const [customerContacts, setCustomerContacts] = useState([]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    let clientNameInsured = newPolicy?.clientNameInsured;
    if (name === 'idCustomerOrganization') {
      setFilterCustomerOrgId(value);
      if (!newPolicy?.clientNameInsured) {
        clientNameInsured = customerOrg?.filter((item) => item.id === value)[0]?.name;
      }
      setNewPolicy({
        ...newPolicy,
        clientNameInsured,
        idCustomerOrganization: value,
        idCustomerContact: ''
      });
    } else if (
      name === 'idPolicyStatus' &&
      (newPolicy?.idPolicyStatus === 23 || newPolicy?.idPolicyStatus === 24) &&
      value === 22 &&
      actionType !== 'Create'
    ) {
      setNewPolicy({
        ...newPolicy
      });
    } else if (name === 'idAgency') {
      setAgencyId(value);
      setNewPolicy({
        ...newPolicy,
        idAgent: '',
        [name]: value
      });
    } else {
      setNewPolicy({
        ...newPolicy,
        [name]: name.includes('total') ? +value : name.includes('training') ? (value === 'true' ? true : false) : value
      });
    }
  };

  const handleBeginDateChange = (date) => {
    let newDate;
    if (date) {
      newDate = dayjs(date);
    } else {
      newDate = null;
    }

    setNewPolicy({
      ...newPolicy,
      beginDate: newDate
    });
  };

  const handleEndDateChange = (date) => {
    let newDate;
    if (date) {
      newDate = dayjs(date);
    } else {
      newDate = null;
    }

    setNewPolicy({
      ...newPolicy,
      endDate: newDate
    });
  };

  const handleEndDateSelection = (event) => {
    const { value } = event.target;
    setEndDateSelection(value);
    const beginDate = dayjs(newPolicy?.beginDate);
    const endDate = beginDate.clone();
    if (value !== 0 && value !== -1) {
      let newEndDate;
      if (value !== 12) {
        newEndDate = endDate.add(value, 'month');
      } else {
        newEndDate = endDate.add(1, 'year');
      }

      console.log(newEndDate);

      setNewPolicy({
        ...newPolicy,
        endDate: newEndDate
      });
    }
  };

  const handleClose = () => {
    setEndDateSelection(-1);
    handleClosedialog();
  };

  const handleSave = () => {
    let returnPolicy;
    if (!customerOrgId) {
      returnPolicy = {
        policy: {
          idCustomerOrganization: newPolicy.idCustomerOrganization,
          idAgent: newPolicy.idAgent,
          idAgency: newPolicy?.idAgency,
          idCompanyUser: newPolicy?.idCompanyUser,
          clientNameInsured: newPolicy?.clientNameInsured,
          endorsements: newPolicy?.endorsements || '',
          idInsurance: newPolicy?.idInsurance || null,
          insuranceNotes: newPolicy?.insuranceNotes || '',
          beginDate: newPolicy?.beginDate || '',
          endDate: newPolicy?.endDate || '',
          idPolicyType: newPolicy.idPolicyType,
          policyNumber: newPolicy?.policyNumber || '',
          idPolicyStatus: newPolicy?.idPolicyStatus,
          trainingRequired: newPolicy.trainingRequired,
          idParentPolicy: newPolicy?.idParentPolicy || null,
          idCustomerContact: newPolicy?.idCustomerContact || ''
        }
      };
    } else if (isPolicyAircraft) {
      returnPolicy = {
        policy: {
          idPolicy: newPolicy.idPolicy,
          idLiability: newPolicy?.idLiability,
          idLiabilityAmount: newPolicy?.idLiabilityAmount,
          idMedical: newPolicy?.idMedical,
          idMedicalAmount: newPolicy?.idMedicalAmount,
          idHull: newPolicy?.idHull,
          idHullAmount: newPolicy?.idHullAmount,
          idTerritory: newPolicy?.idTerritory,
          idTerritoryAmount: newPolicy?.idTerritoryAmount,
          // idUse: newPolicy?.idUse,
          // idUseAmount: newPolicy?.idUseAmount,
          idWarTria: newPolicy?.idWarTria,
          idWarTriaAmount: newPolicy?.idWarTriaAmount,
          taxesFees: newPolicy?.taxesFees,
          idHullIMDeductible: newPolicy?.idHullIMDeductible,
          idHullNIMDeductible: newPolicy?.idHullNIMDeductible
        }
      };
    } else if (actionType === 'Delete') {
      returnPolicy = {
        policy: {
          declinedReason: newPolicy?.declinedReason || ''
        }
      };
    } else {
      returnPolicy = {
        policy: {
          idPolicy: newPolicy?.idPolicy
        }
      };
    }

    if (customerOrgId && isPolicyPilot) {
      returnPolicy.endorsement = newPolicy?.endorsement;
    }

    if (newPolicy?.idAirport && newPolicy?.idPolicyType === 28 && !isPolicyAircraft) {
      returnPolicy.policy = {
        ...returnPolicy.policy,
        idAirport: newPolicy?.idAirport,
        airportHullCode: newPolicy?.airportHullCode,
        airportHullAmount: newPolicy?.airportHullAmount,
        airportLiabilityCode: newPolicy?.airportLiabilityCode,
        airportLiabilityAmount: newPolicy?.airportLiabilityAmount,
        airportTerritoryCode: newPolicy?.airportTerritoryCode,
        airportTerritoryAmount: newPolicy?.airportTerritoryAmount,
        airportUseCode: newPolicy?.airportUseCode,
        airportUseAmount: newPolicy?.airportUseAmount,
        airportMedicalCode: newPolicy?.airportMedicalCode,
        airportMedicalAmount: newPolicy?.airportMedicalAmount,
        airportWarTriaCode: newPolicy?.airportWarTriaCode,
        airportWarTriaAmount: newPolicy?.airportWarTriaAmount
      };
    } else {
      returnPolicy.policy = {
        ...returnPolicy?.policy
      };
    }
    return handlePolicySave(returnPolicy);
  };

  useEffect(() => {
    const getCustomerContacts = async () => {
      const response = await axios.get(`/api/customer-contact/customerOrgId/${filterCustomerOrgId}/companyId/${companyId}`);
      setCustomerContacts([...response.data]);
    };
    if (filterCustomerOrgId) {
      getCustomerContacts();
    }
  }, [filterCustomerOrgId, companyId]);
  useEffect(() => {
    const getPolicyStatus = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=PolicyStatus`);
      setPolicyStatus([...response.data]);
    };

    const getPolicyTypes = async () => {
      const response = await axios.get(`/api/options/companyId/${companyId}?category=PolicyType`);
      setPolicyTypes([...response.data]);
    };

    const getAirports = async () => {
      const response = await axios.get(`/api/airport/companyId/${companyId}`);
      setAirports([...response.data.airports]);
    };

    const getInsurances = async () => {
      const response = await axios.get(`/api/insurance/companyId/${companyId}`);
      setInsurances([...response.data]);
    };

    const getAgencies = async () => {
      const response = await axios.get(`/api/agency/companyId/${companyId}`);
      setAgencies([...response.data]);
    };

    const getAgents = async () => {
      const response = await axios.get(`/api/agent/companyId/${companyId}`);
      setAgents([...response.data]);
    };

    if (policy) {
      const propPolicy = { ...policy };
      setAgencyId(policy?.idAgency);
      setFilterCustomerOrgId(policy?.idCustomerOrganization);
      setNewPolicy(propPolicy);
    }

    getPolicyStatus();
    getPolicyTypes();
    getInsurances();
    getAgencies();
    getAgents();
    getAirports();
  }, [policy]);

  useEffect(() => {
    const getPolicies = async () => {
      const relations = policyRelations?.map((rel) => rel.Policy.id) || [];
      const result = await axios.get(`/api/policy/companyId/${companyId}/customerOrgId/${customerOrgId}`);
      let tempArray = [...result.data];
      tempArray = tempArray.filter((pol) => (relations.includes(pol.id) ? false : true));
      setPolicies(tempArray);
    };
    const getLiabilities = async () => {
      const response = await axios.get(`/api/poaLiability/companyId/${companyId}`);
      setAircraftLiabilities([...response.data]);
    };

    const getHulls = async () => {
      const response = await axios.get(`/api/poaHull/companyId/${companyId}`);
      setAircraftHulls([...response.data]);
    };

    const getMedical = async () => {
      const response = await axios.get(`/api/poaMedical/companyId/${companyId}`);
      setAircraftMedicals([...response.data]);
    };

    const getTerritory = async () => {
      const response = await axios.get(`/api/poaTerritory/companyId/${companyId}`);
      setAircraftTerritories([...response.data]);
    };

    // const getUse = async () => {
    //   const response = await axios.get(`/api/poaUse/companyId/${companyId}`);
    //   setAircraftUses([...response.data]);
    // };

    const getWarTria = async () => {
      const response = await axios.get(`/api/poaWarTria/companyId/${companyId}`);
      setAircraftWarTrias([...response.data]);
    };
    if (customerOrgId) {
      getPolicies();
      if (isPolicyAircraft) {
        getLiabilities();
        getHulls();
        getMedical();
        getTerritory();
        // getUse();
        getWarTria();
      }
    }
  }, [customerOrgId, policyRelations]);
  const saveInput = React.useRef();
  return (
    <>
      <Dialog
        open={open}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            saveInput.current.click();
          }
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            height: '100vh',
            '& .MuiDialog-paper': {
              m: 0,
              borderRadius: 0,
              maxWidth: 450,
              minWidth: 200,
              maxHeight: '100%',
              height: '100vh'
            }
          }
        }}
      >
        <DialogTitle>
          {actionType === 'Create' || (actionType === 'Details' && newPolicy?.actionType !== 'Edit')
            ? 'New Policy'
            : actionType === 'Edit' || newPolicy?.actionType === 'Edit'
            ? 'Edit Policy'
            : actionType === 'Delete'
            ? 'Delete Policy'
            : ''}
        </DialogTitle>
        <DialogContent>
          {actionType !== 'Delete' ? (
            actionType === 'Details' ? (
              <Grid container spacing={gridSpacing}>
                {newPolicy?.actionType !== 'Edit' && (
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ mt: 1 }}
                      fullWidth
                      options={policies}
                      getOptionLabel={(option) => `${option.id}: ${option?.policyNumber}`}
                      value={policies.find((pil) => pil.id === newPolicy.idPolicy) || null}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(_, newValue) => {
                        const selectedPolicyId = newValue ? newValue.id : null;
                        handleChange({ target: { name: 'idPolicy', value: selectedPolicyId } });
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={!newPolicy?.idPolicy}
                          helperText={!newPolicy?.idPolicy ? 'Must not be Empty' : ''}
                          {...params}
                          label="Policies"
                        />
                      )}
                    />
                  </Grid>
                )}
                {/* {isPolicyPilot && (
                  <Grid item xs={12}>
                    <TextField
                      sx={{ mt: 1 }}
                      fullWidth
                      label="Endorsements"
                      name="endorsement"
                      multiline
                      minRows={3}
                      maxRows={6}
                      value={newPolicy?.endorsement || ''}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                )} */}
                {isPolicyAircraft && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.idLiability || ''}
                        label="Aircraft Liability"
                        name="idLiability"
                        onChange={handleChange}
                      >
                        {deactiveArrayUtil(aircraftLiabilities, {
                          id: newPolicy?.idLiability,
                          shortCode: newPolicy?.PolicyOptionAircraftLiability?.shortCode
                        })?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.shortCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Liability Amount"
                        id="filled-start-adornment1"
                        name="idLiabilityAmount"
                        value={newPolicy?.idLiabilityAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.idHull || ''}
                        label="Aircraft Hull"
                        name="idHull"
                        onChange={handleChange}
                      >
                        {deactiveArrayUtil(aircraftHulls, {
                          id: newPolicy?.idHull,
                          shortCode: newPolicy?.PolicyOptionAircraftHull?.shortCode
                        })?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.shortCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Hull Amount"
                        id="filled-start-adornment2"
                        name="idHullAmount"
                        value={newPolicy?.idHullAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.idMedical || ''}
                        label="Aircraft Medical"
                        name="idMedical"
                        onChange={handleChange}
                      >
                        {deactiveArrayUtil(aircraftMedicals, {
                          id: newPolicy?.idMedical,
                          shortCode: newPolicy?.PolicyOptionAircraftMedical?.shortCode
                        }).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.shortCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Medical Amount"
                        id="filled-start-adornment3"
                        name="idMedicalAmount"
                        value={newPolicy?.idMedicalAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.idTerritory || ''}
                        label="Aircraft Territory"
                        name="idTerritory"
                        onChange={handleChange}
                      >
                        {deactiveArrayUtil(aircraftTerritories, {
                          id: newPolicy?.idTerritory,
                          shortCode: newPolicy?.PolicyOptionAircraftTerritory?.shortCode
                        }).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.shortCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Territory Amount"
                        id="filled-start-adornment4"
                        name="idTerritoryAmount"
                        value={newPolicy?.idTerritoryAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    {/* <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.idUse || ''}
                        label="Aircraft Use"
                        name="idUse"
                        onChange={handleChange}
                      >
                        {deactiveArrayUtil(aircraftUses, {
                          id: newPolicy?.idUse,
                          shortCode: newPolicy?.PolicyOptionAircraftUse?.shortCode
                        }).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.shortCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Use Amount"
                        id="filled-start-adornment5"
                        name="idUseAmount"
                        value={newPolicy?.idUseAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid> */}
                    <Grid item xs={6}>
                      <TextField
                        select
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.idWarTria || ''}
                        label="Aircraft WarTria"
                        name="idWarTria"
                        onChange={handleChange}
                      >
                        {deactiveArrayUtil(aircraftWarTrias, {
                          id: newPolicy?.idWarTria,
                          shortCode: newPolicy?.PolicyOptionAircraftWarTria?.shortCode
                        }).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.shortCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="WarTria Amount"
                        id="filled-start-adornment5"
                        name="idWarTriaAmount"
                        value={newPolicy?.idWarTriaAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Hull NIM Deductible"
                        id="filled-start-adornment7"
                        name="idHullNIMDeductible"
                        value={newPolicy?.idHullNIMDeductible || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Hull IM Deductible"
                        id="filled-start-adornment7"
                        name="idHullIMDeductible"
                        value={newPolicy?.idHullIMDeductible || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Taxes and Fee's"
                        id="filled-start-adornment6"
                        name="taxesfees"
                        value={newPolicy?.taxesFees || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            ) : (
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">Policy Types</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id={`demo-simple-select-${!newPolicy?.idPolicyType ? 'error' : 'outlined'}`}
                      value={newPolicy.idPolicyType || ''}
                      onChange={handleChange}
                      name="idPolicyType"
                      input={<Input />}
                      MenuProps={MenuProps}
                      error={!newPolicy.idPolicyType}
                    >
                      {policyTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.val}
                        </MenuItem>
                      ))}
                    </Select>
                    {!newPolicy.idPolicyType && <FormHelperText style={{ color: '#ff413a' }}>Policy Type cannot be Empty</FormHelperText>}
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <Autocomplete
                    sx={{ mt: 1 }}
                    fullWidth
                    options={deactiveArrayUtil(customerOrg, {
                      id: newPolicy?.idCustomerOrganization,
                      name: newPolicy?.CustomerOrganization?.name
                    })}
                    getOptionLabel={(option) => ` ${option.id}: ${option.name}`}
                    value={
                      deactiveArrayUtil(customerOrg, {
                        id: newPolicy?.idCustomerOrganization,
                        name: newPolicy?.CustomerOrganization?.name
                      }).find((pil) => pil.id === newPolicy.idCustomerOrganization) || null
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      const selectedCustomerOrgId = newValue === undefined ? null : newValue?.id;
                      handleChange({
                        target: { name: 'idCustomerOrganization', value: selectedCustomerOrgId }
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!newPolicy?.idCustomerOrganization}
                        helperText={!newPolicy?.idCustomerOrganization ? 'Must not be Emoty' : ''}
                        {...params}
                        label="Customer Organization*"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">Customer Contact</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id={`demo-simple-select-${!newPolicy?.idCustomerOrganization ? 'error' : 'outlined'}`}
                      value={newPolicy?.idCustomerContact || ''}
                      onChange={handleChange}
                      name="idCustomerContact"
                      input={<Input />}
                      MenuProps={MenuProps}
                      disabled={!newPolicy?.idCustomerOrganization}
                    >
                      {deactiveArrayUtil(customerContacts, { id: newPolicy?.idCustomerContact, name: newPolicy?.CustomerContact?.name })
                        ?.filter((item) => item.idCustomerOrganization === newPolicy?.idCustomerOrganization)
                        ?.map((cc) => (
                          <MenuItem key={cc.id} value={cc.id}>
                            {cc.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">Agencies*</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id={`demo-simple-select-${!newPolicy?.idAgency ? 'error' : 'outlined'}`}
                      value={newPolicy?.idAgency || ''}
                      onChange={handleChange}
                      name="idAgency"
                      input={<Input />}
                      error={!newPolicy.idAgency}
                    >
                      {deactiveArrayUtil(agencies, {
                        id: newPolicy?.idAgency,
                        name: newPolicy?.Agency?.name
                      }).map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {!newPolicy.idAgency && <FormHelperText style={{ color: '#ff413a' }}>Agency cannot be Empty</FormHelperText>}
                  </FormControlCustom>
                </Grid>
                {agents.length > 0 && (
                  <Grid item xs={12} className="fixSelector">
                    <FormControlCustom variant="standard">
                      <InputLabel id="demo-simple-select-outlined-label">Agents*</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={`demo-simple-select-${!newPolicy?.idAgent ? 'error' : 'outlined'}`}
                        value={newPolicy?.idAgent || ''}
                        onChange={handleChange}
                        name="idAgent"
                        input={<Input />}
                        disabled={!newPolicy?.idAgency}
                        error={!newPolicy.idAgent}
                      >
                        {deactiveArrayUtil(agents, {
                          id: newPolicy?.idAgent,
                          displayName: newPolicy?.Agent?.displayName
                        })
                          ?.filter((item) => item.idAgency === agencyId)
                          .map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.displayName}
                            </MenuItem>
                          ))}
                      </Select>
                      {!newPolicy.idAgent && <FormHelperText style={{ color: '#ff413a' }}>Agents cannot be Empty</FormHelperText>}
                    </FormControlCustom>
                  </Grid>
                )}
                {users?.length > 0 && (
                  <Grid item xs={12} className="fixSelector">
                    <FormControlCustom variant="standard">
                      <InputLabel id="demo-simple-select-outlined-label">Company Agents*</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={`demo-simple-select-${!newPolicy?.idCompanyUser ? 'error' : 'outlined'}`}
                        value={newPolicy?.idCompanyUser || ''}
                        onChange={handleChange}
                        name="idCompanyUser"
                        input={<Input />}
                        error={!newPolicy.idCompanyUser}
                      >
                        {deactiveArrayUtil(users, {
                          id: newPolicy?.idCompanyUser,
                          displayName: newPolicy?.['UserProfile_Aircraft_idCompanyUserToUserProfile']?.displayName
                        }).map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                      {!newPolicy.idCompanyUser && (
                        <FormHelperText style={{ color: '#ff413a' }}>Company Users cannot be Empty</FormHelperText>
                      )}
                    </FormControlCustom>
                  </Grid>
                )}
                {airports?.length > 0 && newPolicy?.idPolicyType === 28 && (
                  <Grid item xs={12} className="fixSelector">
                    <FormControlCustom variant="standard">
                      <InputLabel id="demo-simple-select-outlined-label">Airport</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        value={newPolicy?.idAirport || ''}
                        onChange={handleChange}
                        name="idAirport"
                        error={!newPolicy?.idAirport}
                        input={<Input />}
                      >
                        {deactiveArrayUtil(airports, {
                          id: newPolicy?.idAirport,
                          name: newPolicy?.Airport?.name
                        }).map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {!newPolicy.idAirport && <FormHelperText style={{ color: '#ff413a' }}>Airport cannot be Empty</FormHelperText>}
                    </FormControlCustom>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Client's Name"
                    name="clientNameInsured"
                    variant="outlined"
                    required
                    onChange={handleChange}
                    value={newPolicy?.clientNameInsured || ''}
                    error={!newPolicy?.clientNameInsured}
                    helperText={
                      !newPolicy?.clientNameInsured ? 'Cannot be Empty will default to customer organization name if selected' : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Endorsements"
                    name="endorsements"
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={newPolicy?.endorsements || ''}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">Insurance</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      value={newPolicy.idInsurance || ''}
                      onChange={handleChange}
                      name="idInsurance"
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {deactiveArrayUtil(insurances, { id: newPolicy?.idInsurance, name: newPolicy?.insurance?.name }).map((insurance) => (
                        <MenuItem key={insurance.id} value={insurance.id}>
                          {insurance.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Insurance Notes"
                    name="insuranceNotes"
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={newPolicy?.insuranceNotes || ''}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    label="Begin Date"
                    value={newPolicy?.beginDate ? formatDate(newPolicy.beginDate) : null}
                    onChange={handleBeginDateChange}
                    inputFormat="MM/dd/yyyy"
                    sx={{ width: '100%' }}
                    slotProps={{
                      textField: {
                        error: !newPolicy?.beginDate,
                        helperText: !newPolicy?.beginDate ? 'Begin Date is Required' : '',
                        fullWidth: true
                      }
                    }}
                  />
                </Grid>
                {actionType === 'Create' && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label="End Date"
                      value={endDateSelection}
                      onChange={handleEndDateSelection}
                      disabled={!newPolicy.beginDate}
                      error={endDateSelection === -1}
                      helperText={endDateSelection === -1 ? 'Please assign a Begin date' : ''}
                    >
                      {EndDateSelections.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <DateTimePicker
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    label="End Date"
                    sx={{ width: '100%' }}
                    value={newPolicy?.endDate ? formatDate(newPolicy.endDate) : null}
                    disabled={actionType === 'Create' ? endDateSelection !== 0 : false}
                    slotProps={{
                      textField: {
                        error: !newPolicy?.endDate,
                        helperText: !newPolicy?.endDate ? 'Please Assign a end date for policy' : '',
                        fullWidth: true
                      }
                    }}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    keyboardIcon={<EventTwoToneIcon />}
                  />
                </Grid>
                {newPolicy?.idPolicyType === 28 && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.airportLiabilityCode || ''}
                        label="Airport Liability"
                        name="airportLiabilityCode"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Liability Amount"
                        id="filled-start-adornment1"
                        name="airportLiabilityAmount"
                        value={newPolicy?.airportLiabilityAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.airportHullCode || ''}
                        label="Airport Hull"
                        name="airportHullCode"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Hull Amount"
                        id="filled-start-adornment2"
                        name="airportHullAmount"
                        value={newPolicy?.airportHullAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.airportMedicalCode || ''}
                        label="Airport Medical"
                        name="airportMedicalCode"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Medical Amount"
                        id="filled-start-adornment3"
                        name="airportMedicalAmount"
                        value={newPolicy?.airportMedicalAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.airportTerritoryCode || ''}
                        label="Airport Territory"
                        name="airportTerritoryCode"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Territory Amount"
                        id="filled-start-adornment4"
                        name="airportTerritoryAmount"
                        value={newPolicy?.airportTerritoryAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.airportUseCode || ''}
                        label="Aircraft Use"
                        name="airportUseCode"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="Use Amount"
                        id="filled-start-adornment5"
                        name="airportUseAmount"
                        value={newPolicy?.airportUseAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{ mt: 1 }}
                        value={newPolicy?.airportWarTriaCode || ''}
                        label="Airport WarTria"
                        name="airportWarTriaCode"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        sx={{ mt: 1 }}
                        label="WarTria Amount"
                        id="filled-start-adornment5"
                        name="airportWarTriaAmount"
                        value={newPolicy?.airportWarTriaAmount || ''}
                        type="number"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0 }, startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Policy Number"
                    name="policyNumber"
                    variant="outlined"
                    value={newPolicy?.policyNumber || ''}
                    error={!newPolicy?.policyNumber}
                    helperText={!newPolicy?.policyNumber ? 'This field cannot be Empty' : ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} className="fixSelector">
                  <FormControlCustom variant="standard">
                    <InputLabel id="demo-simple-select-outlined-label">Policy Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id={`demo-simple-select-${!newPolicy?.idPolicyStatus ? 'error' : 'outlined'}`}
                      value={newPolicy.idPolicyStatus || ''}
                      onChange={handleChange}
                      name="idPolicyStatus"
                      input={<Input />}
                      error={!newPolicy.idPolicyStatus}
                    >
                      {policyStatus.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.val}
                        </MenuItem>
                      ))}
                    </Select>
                    {!newPolicy.idPolicyStatus && (
                      <FormHelperText style={{ color: '#ff413a' }}>Policy Status cannot be Empty</FormHelperText>
                    )}
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12}>
                  <CustomDialogTitle>Training Required</CustomDialogTitle>

                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="trainingRequired"
                      value={newPolicy?.trainingRequired || false}
                      onChange={handleChange}
                    >
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                        </Grid>
                        <Grid item>
                          <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )
          ) : (
            <>
              <DialogContentText>Confirm if you want to delete the this Policy</DialogContentText>
              <Grid item xs={12}>
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Declined Reason"
                  name="declinedReason"
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={newPolicy?.declinedReason || ''}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              actionType !== 'Delete'
                ? actionType !== 'Details'
                  ? !newPolicy?.idAgent ||
                    !newPolicy?.idCustomerOrganization ||
                    !newPolicy?.idPolicyType ||
                    !newPolicy?.idPolicyStatus ||
                    !newPolicy?.policyNumber ||
                    !newPolicy?.endDate ||
                    (endDateSelection === -1 && actionType === 'Create') ||
                    !newPolicy?.beginDate ||
                    (newPolicy?.idPolicyType === 28 && !newPolicy?.idAirport)
                  : !newPolicy?.idPolicy
                : false
            }
          >
            {actionType !== 'Delete' ? 'Save' : 'Delete'}
          </Button>
          <Button variant="text" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PolicyDialog;
