import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const IllinoisEndorsement = () => (
  <Page size="LETTER" style={styles.page}>
    <View style={[styles.header, { fontSize: 8 }]}>
      <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
    </View>
    <View style={styles.header}>
      <Text style={styles.boldText}>Illinois Change Endorsement</Text>
    </View>
    <View style={styles.content}>
      <View>
        <Text>
          This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text> Paragraph
          8, Canceling the Policy, to read as follows.
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
          <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
            <Text>Canceling the Policy</Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling{' '}
              <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
              <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
              want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the policy,{' '}
              <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium <Text style={styles.boldText}>you</Text> have paid.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
              as follows:
            </Text>
          </View>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  This policy can be canceled at any time for nonpayment of premium by mailing a notice of cancellation to{' '}
                  <Text style={styles.boldText}>you</Text> at least 10 days before the effective date of the cancellation; or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  If this policay has been in effect for 60 days or less, it may be canceled at any time by mailing a notice of cancellation
                  to <Text style={styles.boldText}>you</Text> at least 30 days before the effective date of cancellation; or
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
              <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text>
                  If the policy has been in effect for more than 60 days it may be canceled at any time by mailing a notice of cancellation
                  to <Text style={styles.boldText}>you</Text> at least 60 days before the effective date of cancellation and only for one of
                  the following reasons:
                </Text>
              </View>
              <View style={[styles.nestedListContainer, { gap: 1 }]}>
                <View>
                  <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>The policy was obtained through a material misrepresentation;</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>You have violated any of the terms or conditions of the policy;</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>The risk originally accepted has measurably increased;</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      Certification to the Director of Insurance of the State of Illionois of the loss of reinsurance by{' '}
                      <Text style={styles.boldText}>us</Text> which provided coverage to us for all or a substantial part of the underlying
                      risk insured; or
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                  <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                    <Text>
                      A determination by the Director of Insurance for the State of Illinois that the continuation of the policy could place
                      us in violation of the insurance laws of the State of Illinois.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>Any notice of cancellation will state the reason for cancellation.</Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              Proof of mailing of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will compute the premium
              earned by <Text style={styles.boldText}>us</Text> based on the percentage of the original policy period that{' '}
              <Text style={styles.boldText}>we</Text> provided coverage.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              <Text style={styles.boldText}>We</Text> will return to <Text style={styles.boldText}>you</Text> any premium that{' '}
              <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, making the
              refund is not a condition of cancellation.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text> of
              the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage coverage,{' '}
              <Text style={styles.boldText}>we</Text> are entitled to the total AIrcraft Physical Damage premium shown on the Coverage
              Identification PAge for the <Text style={styles.boldText}>aircraft</Text> on which <Text style={styles.boldText}>we</Text>{' '}
              made or make payment. <Text style={styles.boldText}>You</Text> agree to pay any premium that may be due or permit us to deduct
              such premium from <Text style={styles.boldText}>our</Text> loss payment.
            </Text>
          </View>
          <View style={styles.listItemParagraph}>
            <Text>
              If <Text style={styles.boldText}>we</Text> dedicde not to renew this policy, a premium increase of 30% or more, or any change
              in the deductible, <Text style={styles.boldText}>we</Text> will mail written notice stating the reason for non-renewal to{' '}
              <Text style={styles.boldText}>your</Text> last mailing address know to us at least 60 days before the expiration date of this
              policy. Proof of mailing of any notice is sufficient proof of notice.
            </Text>
          </View>
        </View>
      </View>
    </View>
    <FooterPDF page={1} endPage={1} />
  </Page>
);

export default IllinoisEndorsement;
