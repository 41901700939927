import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { PageFooter } from '../PageFooter';
import { styles } from '../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

export const Part4PDF = () => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            5.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Additional Protection
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> also provide the following additional protection with{' '}
            <Text style={styles.boldText}>your</Text> coverage under PART THREE as long as <Text style={styles.boldText}>we</Text> have not
            paid the limits of coverage that <Text style={styles.boldText}>you</Text> have purchased.
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Defend Claims</Text>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Defend at <Text style={styles.boldText}>our</Text> expense with attorneys <Text style={styles.boldText}>we</Text> choose,
                any claim or legal action against <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> with respect to any claims for{' '}
                <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> resulting from an{' '}
                <Text style={styles.boldText}>occurrence we</Text> cover. <Text style={styles.boldText}>We</Text> may investigate,
                negotiate, or settle any claim or legal action as <Text style={styles.boldText}>we</Text> elect;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Pay Expenses</Text>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Pay the expenses, and court costs incurred, claims or legal actions <Text style={styles.boldText}>we</Text> defend;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Reimburse Expenses</Text>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Reimburse <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>someone we protect</Text> for all
                reasonable expenses incurred at <Text style={styles.boldText}>our</Text> request, but{' '}
                <Text style={styles.boldText}>we</Text> will not pay for loss of wages or earnings;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Pay Interest</Text>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Pay post judgment interest on the part of a judgment against you or someone we protect that we are obligated to pay until we
                have made payment or tendered or deposited it in court;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Pay Costs of Bonds</Text>
              <Text style={styles.listItemHeading}>
                e.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Pay premiums on bonds required to release attachments and to appeal from judgments <Text style={styles.boldText}>we</Text>{' '}
                elect to appeal, but <Text style={styles.boldText}>we</Text> will not pay for bonds covering any aggregate amount more than
                the applicable limit of coverage; and
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Comply with Financial Responsibility Laws</Text>
              <Text style={styles.listItemHeading}>
                f.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Comply with the provisions of any <Text style={styles.boldText}>aircraft</Text> financial responsibility statute if{' '}
                <Text style={styles.boldText}>we</Text> certify <Text style={styles.boldText}>your</Text> policy as proof of{' '}
                <Text style={styles.boldText}>your</Text>
                future financial responsibility under that statute. <Text style={styles.boldText}>We</Text> will not, however, pay more than
                the limits of coverage shown in Item 6 of the Coverage Identification Page. <Text style={styles.boldText}>You</Text> agree
                to reimburse <Text style={styles.boldText}>us</Text> for any amount <Text style={styles.boldText}>we</Text> have to pay in
                complying with the statute that <Text style={styles.boldText}>we</Text> would not otherwise have had to pay.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.header}>
      <Text>Part Four. MEDICAL EXPENSE COVERAGE</Text>
    </View>
    <View style={styles.contentDivider} />
    <View style={styles.content}>
      <View>
        <Text>
          Review Item 6E of <Text style={styles.boldText}>your</Text> Coverage Identification Page to confirm the Medical Expense coverage
          and limits issued to <Text style={styles.boldText}>you.</Text>
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            1.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Will Pay
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will pay the reasonable and necessary medical expense incurred within one year for
            injuries to <Text style={styles.boldText}>you</Text> and any <Text style={styles.boldText}>passenger</Text> caused by an{' '}
            <Text style={styles.boldText}>occurrence</Text> while the <Text style={styles.boldText}>aircraft</Text> was operated by{' '}
            <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect.</Text> Medical expense includes
            the cost of medical, surgical, dental, hospital, professional nursing, ambulance or funeral services. The most we will pay for
            each person’s medical expense is shown under Item 6E opposite “each person.” The most <Text style={styles.boldText}>we</Text>{' '}
            will pay for all medical expense is shown under Item 6E opposite “each occurrence.”
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            2.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Whom <Text style={styles.boldText}>We</Text> Will Pay
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will pay each injured person directly, the person responsible for payment, or the person
            or organization that provided the service.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            3.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Will Not Pay
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will not pay any medical expense to the extent payment is required under any worker’s
            compensation or disability benefits law or similar law.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            4.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Effect of Payment
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> are not admitting that <Text style={styles.boldText}>you</Text> have any legal liability
            or responsibility by making medical expense payments
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            5.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Proof of Claim
          </Text>
          <Text style={styles.listItemParagraph}>
            The injured person or someone acting for the person must give <Text style={styles.boldText}>us</Text> written proof of the
            medical expense and must help <Text style={styles.boldText}>us</Text> obtain the medical records and reports{' '}
            <Text style={styles.boldText}>we</Text> need. If <Text style={styles.boldText}>we</Text> ask, the injured person must submit to
            an examination by any doctor <Text style={styles.boldText}>we</Text> select.
          </Text>
        </View>
        <View style={[styles.listItem, { marginBottom: '-30px' }]}>
          <Text style={styles.listItemHeading}>
            6.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Legal Action for Medical Expenses
          </Text>
          <Text style={styles.listItemParagraph}>
            A legal action against <Text style={styles.boldText}>us</Text> for medical expenses cannot be brought unless{' '}
            <Text style={styles.boldText}>you</Text> or <Text style={styles.boldText}>someone we protect</Text> have done everything that{' '}
            <Text style={styles.boldText}>you</Text>
            or <Text style={styles.boldText}>someone we protect</Text> is required to do and at least 30 days has passed since proof of the
            claim has been given to <Text style={styles.boldText}>us.</Text>u
          </Text>
        </View>
      </View>
    </View>
    <PageFooter form={'S1WD GA 92 40 03 2V'} number={10} />
  </Page>
);
