// react Imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Mui Imports
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Box,
  TableHead,
  Tabs,
  Tab,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Autocomplete
} from '@mui/material';
import Breadcrumb from 'component/Breadcrumb';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Local Imports
import useAuth from 'hooks/useAuth';
import initializeAxios from 'utils/axios';
import { gridSpacing } from 'config.js';

// Icons
import { Add } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import PilotDialog from 'component/PilotDialog';
import PolicyDialog from 'component/PolicyDialog';
import { states } from 'utils/states';
import LoadingOverlay from 'component/LoadingOverlay';
import LienholderDialog from 'component/Lienholder';
import { deactiveArrayUtil } from 'utils/deactiveArrayUtil';
import AlertDialogSlide from 'views/Elements/Advance/UIDialog/AlertDialogSlide';
import { formatDate } from 'utils/FormatUtils';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(CustomParseFormat);

const AccountTabs = styled((props) => <Tabs {...props} />)(() => ({
  marginBottom: '24px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '.MuiButtonBase-root': {
    minWidth: '120px',
    minHeight: 'auto',
    '.MuiTouchRipple-root': {
      flexDirection: 'row',
      '.MuiSvgIcon-root': {
        marginRight: '10px',
        marginBottom: '0 !important'
      }
    }
  }
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

const AircraftDetails = () => {
  // Initialize Axios
  const auth = useAuth();
  const axios = initializeAxios(auth);
  const navigate = useNavigate();

  // Reactive States
  const { companyId, aircraftId } = useParams();
  const [aircraftDetails, setAircraftDetails] = useState({});

  const [pilots, setPilots] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [customerOrg, setCustomerOrg] = useState([]);
  const [editAircraftPolicy, setEditAircraftPolicy] = useState({});
  const [editAircraftPolicyId, setEditAircraftPolicyId] = useState(null);
  const [openPilot, setOpenPilot] = useState(false);
  const [openPolicy, setOpenPolicy] = useState(false);
  const [openLienholder, setOpenLienholder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lienHolders, setLienHolders] = useState([]);
  const [deleteObject, setDeleteObject] = useState({ type: '', relation: {} });
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState({
    idAgent: null,
    idCustomerOrganization: null
  });

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rowExpandStates, setRowExpandStates] = useState([]);

  const toggleExpand = (index) => {
    // Create a new array of expand states and toggle the state for the clicked row
    const newRowExpandStates = [...rowExpandStates];
    newRowExpandStates[index] = !newRowExpandStates[index];
    setRowExpandStates(newRowExpandStates);
  };

  const handlePolicyOpen = () => {
    setOpenPolicy(true);
  };

  const handleClosedialog = () => {
    setOpenPilot(false);
    setOpenPolicy(false);
    setOpenLienholder(false);
    setEditAircraftPolicy({});
  };

  const handleCloseModal = () => {
    setDeleteObject({ type: '', relation: {} });
    setOpen(false);
  };

  const handlePilotAdd = () => {
    setOpenPilot(true);
  };

  const handleLienHolderAdd = () => {
    setOpenLienholder(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await axios.put(`/api/aircraft/${aircraftDetails.id}/companyId/${companyId}`, {
      aircraft: {
        registrationNo: aircraftDetails.registrationNo,
        hangered: aircraftDetails.hangered === 'true' ? true : false,
        make: aircraftDetails.make,
        seats: +aircraftDetails.seats,
        type: aircraftDetails.type,
        value: +aircraftDetails.value,
        year: +aircraftDetails.year,
        airportCode: aircraftDetails.airportCode?.trim()?.toUpperCase(),
        idCustomerOrganization: aircraftDetails.idCustomerOrganization,
        registeredOwner: aircraftDetails.registeredOwner,
        registeredEmail: aircraftDetails.registeredEmail,
        registeredPhone: aircraftDetails.registeredPhone,
        registeredAddress1: aircraftDetails.registeredAddress1,
        registeredAddress2: aircraftDetails.registeredAddress2 || '',
        registeredCity: aircraftDetails.registeredCity,
        registeredState: aircraftDetails.registeredState,
        registeredZip: aircraftDetails.registeredZip,
        isLeased: aircraftDetails?.isLeased,
        airportName: aircraftDetails?.airportName,
        cityName: aircraftDetails?.cityName,
        countryName: aircraftDetails?.countryName,
        countryCode: aircraftDetails?.countryCode,
        regionCode: aircraftDetails?.regionCode,
        stateCode: aircraftDetails?.stateCode,
        longitude: aircraftDetails?.longitude,
        latitude: aircraftDetails?.latitude,
        airportType: aircraftDetails?.airportType,
        county: aircraftDetails?.county,
        airportManager: aircraftDetails?.airportManager,
        airportManagerPhone: aircraftDetails?.airportManagerPhone,
        effectiveDate: new Date(aircraftDetails?.effectiveDate)
      },
      detailsFlag: true
    });
    const newAircraftDetails = response.data;
    setAircraftDetails({
      ...newAircraftDetails,
      effectiveDate: formatDate(newAircraftDetails?.effectiveDate)
    });
    setIsLoading(false);
  };

  const handlePilotSave = async (pilot) => {
    setIsLoading(true);
    const response = await axios.post(`/api/aircraft/${aircraftId}/pilot/companyId/${companyId}`, {
      ...pilot,
      id: aircraftId
    });
    setPilots([...pilots, response.data]);
    handleClosedialog();
    setIsLoading(false);
  };

  const handleDeletePilotRelation = async (pilotRelation) => {
    setIsLoading(true);
    await axios.delete(`/api/aircraft/${aircraftId}/pilot/${pilotRelation.id}/companyId/${companyId}`);
    const tempArray = [...pilots];
    const index = tempArray.findIndex((e) => e.id === pilotRelation.id);
    tempArray.splice(index, 1);
    setPilots([...tempArray]);
    setIsLoading(false);
  };

  const handleSavePolicy = async (policy) => {
    setIsLoading(true);
    if (editAircraftPolicy.actionType !== 'Edit') {
      const response = await axios.post(`/api/aircraft/${aircraftId}/policy/companyId/${companyId}`, {
        ...policy
      });
      setPolicies([...policies, response.data]);
    } else {
      const response = await axios.put(`/api/aircraft/${aircraftId}/policy/${editAircraftPolicyId}/companyId/${companyId}`, {
        ...policy
      });
      const tempArray = [...policies];
      const index = tempArray.findIndex((e) => e.id === editAircraftPolicyId);
      tempArray[index] = {
        ...response.data
      };
      setPolicies(tempArray);
    }
    handleClosedialog();
    setIsLoading(false);
  };

  const handleDeletePolicyRelation = async (policyRelation) => {
    setIsLoading(true);
    await axios.delete(`/api/aircraft/${aircraftId}/policy/${policyRelation.id}/companyId/${companyId}`);
    const tempArray = [...policies];
    const index = tempArray.findIndex((e) => e.id === policyRelation.id);
    tempArray.splice(index, 1);
    setPolicies([...tempArray]);
    setIsLoading(false);
  };

  const handleSaveLienholder = async (lienholderDialog) => {
    setIsLoading(true);
    const response = await axios.post(`/api/aircraft/${aircraftId}/lienholder/companyId/${companyId}`, {
      ...lienholderDialog
    });
    setLienHolders([...lienHolders, response.data]);
    handleClosedialog();
    setIsLoading(false);
  };

  const handleDeleteLienholderRelation = async (lienholderRelation) => {
    setIsLoading(true);
    await axios.delete(`/api/aircraft/${aircraftId}/lienholder/${lienholderRelation.id}/companyId/${companyId}`);
    const tempArray = [...lienHolders];
    const index = tempArray.findIndex((e) => e.id === lienholderRelation.id);
    tempArray.splice(index, 1);
    setLienHolders([...tempArray]);
    setIsLoading(false);
  };

  const handleDeleteRelationRoute = (relation) => {
    if (relation?.type === 'Policy') {
      handleDeletePolicyRelation(relation.relation);
    } else if (relation?.type === 'Pilot') {
      handleDeletePilotRelation(relation.relation);
    } else {
      handleDeleteLienholderRelation(relation.relation);
    }
  };

  const handleDeleteRelationModal = (row, type) => {
    setDeleteObject({ type, relation: { ...row } });
    setOpen(true);
  };

  const handleGoToPilotDetail = (pilot) => {
    navigate(`/company/${companyId}/pilots/${pilot.Pilot.id}`);
  };

  const handleGoToPolicyDetail = (policy) => {
    navigate(`/company/${companyId}/policy/${policy.Policy.id}`);
  };

  const handleOnBlurAirport = async (event) => {
    const { value } = event.target;
    if (value.trim().length === 3) {
      const response = await axios.get('/api/airport/airportInfo', {
        params: {
          faaCode: value?.toUpperCase()
        }
      });
      setAircraftDetails({
        ...aircraftDetails,
        airportName: response.data.name,
        cityName: response.data.cityName,
        countryName: response.data.countryName,
        countryCode: response.data.countryCode,
        regionCode: response.data.regionCode,
        stateCode: response.data.stateCode,
        longitude: response.data.longitude,
        latitude: response.data.latitude,
        airportType: response.data.airportType,
        county: response.data.county,
        airportManager: response.data.manager,
        airportManagerPhone: response.data.managerPhone,
        effectiveDate: formatDate(response.data.effectiveDate)
      });
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === 'idCustomerOrganization'){
      const customerOrgItem = customerOrg.find(item => item.id === value);
      setAircraftDetails({
        ...aircraftDetails,
        idCustomerOrganization: value,
        registeredOwner: customerOrgItem?.name,
        registeredAddress1: customerOrgItem?.address1,
        registeredAddress2: customerOrgItem?.address2,
        registeredCity: customerOrgItem?.city,
        registeredState: customerOrgItem?.state,
        registeredZip: customerOrgItem?.zip
      })
    } else {
      setAircraftDetails({
        ...aircraftDetails,
        [name]: value
      });
    }
  };

  const handleDateChange = (event) => {
    let year;
    if (dayjs(event, 'YYYY', false).isValid()) {
      year = event?.format('YYYY');
    } else {
      year = '';
    }

    setAircraftDetails({
      ...aircraftDetails,
      year
    });
  };

  const handleAircraftEdit = (row) => {
    setEditAircraftPolicy({
      ...row,
      actionType: 'Edit'
    });
    setEditAircraftPolicyId(row.id);
    setOpenPolicy(true);
  };

  const calculateTotal = (row) => {
    const calculate = [];
    for (let key in row) {
      if (key.includes('Amount')) {
        calculate.push(+row[key]);
      }
      if (key.includes('Deductible')) {
        calculate.push(+row[key]);
      }
      if (key === 'taxesFees') {
        calculate.push(+row[key]);
      }
    }

    return calculate.reduce((total, next) => total + (next ? next : 0), 0);
  };

  useEffect(() => {
    const getAircraft = async () => {
      setIsLoading(true);
      const response = await axios.get(`/api/aircraft/${aircraftId}/companyId/${companyId}`);
      const aircraft = response.data;
      setAircraftDetails({
        ...response.data,
        effectiveDate: response?.data?.effectiveDate ? formatDate(response?.data?.effectiveDate) : ''
      });
      setPilots(aircraft.PilotAircraft);
      setPolicies(aircraft.PolicyAircraft);
      setLienHolders(aircraft.AircraftLienHolder);
      setIds({
        idAgent: aircraft.idAgent,
        idCustomerOrganization: aircraft.idCustomerOrganization
      });
      setRowExpandStates(policies.map(() => false));
      setIsLoading(false);
    };
    const getCustomerOrgs = async () => {
      const result = await axios.get(`/api/customer-org/companyId/${companyId}`);
      setCustomerOrg(result.data);
    };

    getAircraft();
    getCustomerOrgs();
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mt: { lg: 0, xs: 1 } }}>
          <Breadcrumb title="Aircraft Details" divider={false}>
            <Typography component={Link} to="/" variant="subtitle2" color="inherit" className="link-breadcrumb">
              Home
            </Typography>
            <Typography
              component={Link}
              to={`/company/${companyId}/aircrafts/`}
              variant="subtitle2"
              color="inherit"
              className="link-breadcrumb"
            >
              Aircrafts
            </Typography>
            <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
              Details
            </Typography>
          </Breadcrumb>
        </Grid>
      </Grid>
      <LoadingOverlay loading={isLoading} />
      <Grid item lg={8} xs={12}>
        <Grid container spacing={gridSpacing} xs={12} item sx={{ flexWrap: { lg: 'nowrap', sm: 'wrap' } }} className="grid-container">
          <Grid item xs={12} sx={{ mt: 1 }}>
            <AccountTabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab icon={<DescriptionTwoToneIcon />} iconPosition="start" label="Aircraft" {...a11yProps(0)} />
            </AccountTabs>
            <TabPanel value={value} index={0}>
              <Card>
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm zeroMinWidth>
                      <Typography component="div" variant="h5">
                        Client Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item lg={6} xs={12}>
                          <Typography variant="subtitle2">
                            {customerOrg?.length && (
                              <Autocomplete
                                sx={{ mt: 1 }}
                                fullWidth
                                options={deactiveArrayUtil(customerOrg, {
                                  id: aircraftDetails?.idCustomerOrganization,
                                  name: aircraftDetails?.CustomerOrganization?.name
                                })}
                                getOptionLabel={(option) => ` ${option.id}: ${option.name}`}
                                value={
                                  deactiveArrayUtil(customerOrg, {
                                    id: aircraftDetails?.idCustomerOrganization,
                                    name: aircraftDetails?.CustomerOrganization?.name
                                  }).find((pil) => pil.id === aircraftDetails.idCustomerOrganization) || null
                                }
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(_, newValue) => {
                                  const selectedCustomerOrgId = newValue === undefined ? null : newValue?.id;
                                  handleChange({
                                    target: { name: 'idCustomerOrganization', value: selectedCustomerOrgId }
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    error={!aircraftDetails?.idCustomerOrganization}
                                    helperText={!aircraftDetails?.idCustomerOrganization ? 'Must not be Emoty' : ''}
                                    {...params}
                                    label="Customer Organization*"
                                  />
                                )}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Aircraft Information Details
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitle1">
                            Registration Number: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registrationNo"
                                value={aircraftDetails?.registrationNo || ''}
                                error={!aircraftDetails?.registrationNo}
                                helperText={!aircraftDetails?.registrationNo ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                          <Typography component="div" variant="subtitle1" sx={{ mt: 2 }}>
                            Hangered : &nbsp;
                          </Typography>
                          <Typography component="span" variant="subtitle2">
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="hangered"
                                value={aircraftDetails?.hangered || false}
                                onChange={handleChange}
                              >
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                                  </Grid>
                                  <Grid item>
                                    <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            </FormControl>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitle1">
                            Seats : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                type="number"
                                name="seats"
                                value={aircraftDetails?.seats || ''}
                                error={
                                  !aircraftDetails?.seats || +aircraftDetails?.seats < 0 || aircraftDetails?.seats.toString().includes('-')
                                }
                                helperText={
                                  !aircraftDetails?.seats || +aircraftDetails?.seats < 0 || aircraftDetails?.seats?.toString().includes('-')
                                    ? 'Cannot be Negative or Empty'
                                    : ''
                                }
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                          <Typography component="div" variant="subtitle1" sx={{ mt: 2 }}>
                            Leased : &nbsp;
                          </Typography>
                          <Typography component="span" variant="subtitle2">
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="isLeased"
                                value={aircraftDetails?.isLeased || false}
                                onChange={handleChange}
                              >
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <FormControlLabel value={true} control={<Radio color="default" />} label="Yes" />
                                  </Grid>
                                  <Grid item>
                                    <FormControlLabel value={false} control={<Radio color="default" />} label="No" />
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            </FormControl>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6} sx={{ mt: 4.3 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitle1">
                            Type : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="type"
                                value={aircraftDetails?.type || ''}
                                error={!aircraftDetails?.type}
                                helperText={!aircraftDetails?.type ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                          <Typography component="div" variant="subtitle1" sx={{ mt: 2 }}>
                            Value : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                type="number"
                                name="value"
                                value={aircraftDetails?.value || ''}
                                error={!aircraftDetails?.value || +aircraftDetails?.value < 0 || aircraftDetails?.value?.includes('-')}
                                helperText={
                                  !aircraftDetails?.value || +aircraftDetails?.value < 0 || aircraftDetails?.value?.includes('-')
                                    ? 'Cannot be Negative or Empty'
                                    : ''
                                }
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 1 }}>
                          <Typography component="div" variant="subtitile1">
                            Year : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <DatePicker
                                openTo="year"
                                views={['year']}
                                inputFormat="yyyy"
                                value={aircraftDetails?.year ? formatDate(aircraftDetails?.year?.toString()) : null}
                                readOnly={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                                slotProps={{
                                  textField: {
                                    name: 'year',
                                    error: !dayjs(aircraftDetails?.year?.toString(), 'YYYY', false).isValid() || !aircraftDetails?.year,
                                    onChange: (e) => handleDateChange(e),
                                    helperText:
                                      (!dayjs(aircraftDetails?.year?.toString(), 'YYYY', false).isValid() && 'Invalid Year') ||
                                      (!aircraftDetails?.year && 'Cannot be Empty'),
                                    fullWidth: true,
                                    sx: {
                                      mb: 2
                                    }
                                  }
                                }}
                                disableMaskedInput
                                onChange={handleDateChange}
                              />
                            </Typography>
                          </Typography>
                          <Typography component="div" variant="subtitile1">
                            Make :
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="make"
                                value={aircraftDetails?.make || ''}
                                error={!aircraftDetails?.make}
                                helperText={!aircraftDetails?.make ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Airport Information
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Airport Code : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="airportCode"
                                value={aircraftDetails?.airportCode || ''}
                                error={aircraftDetails?.airportCode?.length !== 3}
                                helperText={aircraftDetails?.airportCode?.length !== 3 ? 'Must be 3 Characters long' : ''}
                                onBlur={handleOnBlurAirport}
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Airport Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="airportName"
                                value={aircraftDetails?.airportName || ''}
                                error={!aircraftDetails?.airportName}
                                helperText={!aircraftDetails?.airportName && 'Required'}
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Airport Type : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="airportType"
                                value={aircraftDetails?.airportType || ''}
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Country Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="countryName"
                                value={aircraftDetails?.countryName || ''}
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            State Code : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="stateCode"
                                value={aircraftDetails?.stateCode || ''}
                                error={!aircraftDetails?.stateCode}
                                helperText={!aircraftDetails?.stateCode && 'Required'}
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            City Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                value={aircraftDetails?.cityName || ''}
                                error={!aircraftDetails?.cityName}
                                helperText={!aircraftDetails?.cityName && 'Required'}
                                name="cityName"
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            County Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                value={aircraftDetails?.county || ''}
                                name="county"
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Longitude : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                value={aircraftDetails?.longitude || ''}
                                name="longitude"
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Latitude : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                value={aircraftDetails?.latitude || ''}
                                name="latitude"
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Manager Name : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                value={aircraftDetails?.airportManager || ''}
                                name="airportManager"
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Manager Phone : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                value={aircraftDetails?.airportManagerPhone || ''}
                                name="airportManagerPhone"
                                variant="outlined"
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitle1">
                            Effective Date : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <DatePicker
                                name="effectiveDate"
                                value={aircraftDetails?.effectiveDate ? formatDate(aircraftDetails?.effectiveDate) : null}
                                readOnly={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                                slotProps={{
                                  textField: {
                                    name: 'effectiveDate',
                                    error:
                                      aircraftDetails?.effectiveDate?.length > 0 ? !dateRegex.test(aircraftDetails?.effectiveDate) : false,
                                    onChange: (e) => handleChange(e),
                                    helperText:
                                      aircraftDetails?.effectiveDate?.length > 0
                                        ? !dateRegex.test(aircraftDetails?.effectiveDate) && 'Incorrect Format mm/dd/yyyy'
                                        : '',
                                    fullWidth: true,
                                    sx: {
                                      pb: 2,
                                      mt: 1
                                    }
                                  }
                                }}
                                disableMaskedInput
                                onChange={handleDateChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography component="div" variant="h5">
                            Registered Information
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered Owner : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredOwner"
                                value={aircraftDetails?.registeredOwner || ''}
                                error={!aircraftDetails?.registeredOwner}
                                helperText={!aircraftDetails?.registeredOwner ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered Email : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredEmail"
                                value={aircraftDetails?.registeredEmail || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered Phone : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredPhone"
                                value={aircraftDetails?.registeredPhone || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered Address 1 : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredAddress1"
                                value={aircraftDetails?.registeredAddress1 || ''}
                                error={!aircraftDetails?.registeredAddress1}
                                helperText={!aircraftDetails?.registeredAddress1 ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered Address 2 : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredAddress2"
                                value={aircraftDetails?.registeredAddress2 || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered City : &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredCity"
                                value={aircraftDetails?.registeredCity || ''}
                                error={!aircraftDetails?.registeredCity}
                                helperText={!aircraftDetails?.registeredCity ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered State: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                select
                                name="registeredState"
                                value={aircraftDetails?.registeredState || ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              >
                                {states.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography component="div" variant="subtitile1" sx={{ mt: 1 }}>
                            Registered Zipcode: &nbsp;
                            <Typography component="span" variant="subtitle2">
                              <TextField
                                sx={{ mt: 1 }}
                                fullWidth
                                name="registeredZip"
                                value={aircraftDetails?.registeredZip || ''}
                                error={!aircraftDetails?.registeredZip}
                                helperText={!aircraftDetails?.registeredZip ? 'Cannot Be Empty' : ''}
                                variant="outlined"
                                InputProps={{
                                  readOnly: !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                                }}
                                onChange={handleChange}
                              />
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item sm={6} md={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={
                              !aircraftDetails?.registrationNo ||
                              aircraftDetails?.airportCode?.trim()?.length !== 3 ||
                              !aircraftDetails?.seats ||
                              +aircraftDetails?.seats < 0 ||
                              aircraftDetails?.seats.toString().includes('-') ||
                              !aircraftDetails?.type ||
                              !aircraftDetails?.value ||
                              +aircraftDetails?.value < 0 ||
                              aircraftDetails?.value?.includes('-') ||
                              !dayjs(aircraftDetails?.year?.toString(), 'YYYY', false).isValid() ||
                              !aircraftDetails?.year ||
                              !aircraftDetails?.make ||
                              !aircraftDetails?.registeredOwner ||
                              !aircraftDetails?.registeredAddress1 ||
                              !aircraftDetails?.registeredCity ||
                              !aircraftDetails?.registeredState ||
                              !aircraftDetails?.registeredZip ||
                              !aircraftDetails?.airportName ||
                              !aircraftDetails?.stateCode ||
                              !aircraftDetails?.cityName ||
                              (aircraftDetails?.effectiveDate?.length > 0 && !dateRegex.test(aircraftDetails?.effectiveDate)) ||
                              !auth.writePrivledge(auth.user, 'Aircraft_Adm')
                            }
                          >
                            Save Changes
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }}>
                <CardContent>
                  <AlertDialogSlide
                    message={`Confirm if you want to delete ${deleteObject.type} Relation`}
                    handleCloseDialog={handleCloseModal}
                    handleResult={handleDeleteRelationRoute}
                    emitValue={deleteObject}
                    open={open}
                  />
                  <Grid item xs={12}>
                    <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography component="div" variant="h5" sx={{ pl: 4 }}>
                        Policies
                      </Typography>
                      <Tooltip title="Add Policy" placement="top">
                        <span>
                          <IconButton
                            color="primary"
                            aria-label="Edit"
                            size="large"
                            sx={{ pr: 4 }}
                            onClick={handlePolicyOpen}
                            disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                          >
                            <Add fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Typography>
                    <Box sx={{ overflowX: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>Policy Number</TableCell>
                            <TableCell align="right">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {policies.map((row, index) => (
                            <React.Fragment key={row.id}>
                              <TableRow>
                                <TableCell>
                                  <IconButton aria-label="expand row" size="small" onClick={() => toggleExpand(index)}>
                                    {rowExpandStates[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <Typography component="div" align="left" variant="subtitle1">
                                    {`${row?.Policy?.policyNumber}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Edit Policy Relation" placement="top">
                                    <span>
                                      <IconButton
                                        color="primary"
                                        aria-label="Edit"
                                        size="large"
                                        disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                                        onClick={() => handleAircraftEdit(row)}
                                      >
                                        <EditTwoToneIcon fontSize="large" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="Delete Policy Relation" placement="top">
                                    <span>
                                      <IconButton
                                        color="secondary"
                                        aria-label="Edit"
                                        size="large"
                                        disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                                        onClick={() => handleDeleteRelationModal(row, 'Policy')}
                                      >
                                        <DeleteIcon fontSize="large" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="Policy Details">
                                    <IconButton color="primary" ara-label="delete" size="large" onClick={() => handleGoToPolicyDetail(row)}>
                                      <DescriptionTwoToneIcon fontSize="large" />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell sx={{ py: 0 }} colSpan={6}>
                                  <Collapse in={rowExpandStates[index]} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="center">Short Code</TableCell>
                                            <TableCell align="center">Amount</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>Liability</TableCell>
                                            <TableCell align="center">{row?.PolicyOptionAircraftLiability?.shortCode}</TableCell>
                                            <TableCell align="center">$ {row?.idLiabilityAmount || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>Medical</TableCell>
                                            <TableCell align="center">{row?.PolicyOptionAircraftMedical?.shortCode}</TableCell>
                                            <TableCell align="center">$ {row?.idMedicalAmount || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>Territory</TableCell>
                                            <TableCell align="center">{row?.PolicyOptionAircraftTerritory?.shortCode}</TableCell>
                                            <TableCell align="center">$ {row?.idTerritoryAmount || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>Use</TableCell>
                                            <TableCell align="center">{row?.PolicyOptionAircraftUse?.shortCode}</TableCell>
                                            <TableCell align="center">$ {row?.idUseAmount || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>WarTria</TableCell>
                                            <TableCell align="center">{row?.PolicyOptionAircraftWarTria?.shortCode}</TableCell>
                                            <TableCell align="center">$ {row?.idWarTriaAmount || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>Hull</TableCell>
                                            <TableCell align="center">{row?.PolicyOptionAircraftHull?.shortCode}</TableCell>
                                            <TableCell align="center">$ {row?.idHullAmount || 0}</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                      <Table sx={{ mt: 2 }}>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">Amount</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>{"Taxes and Fee's"}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">$ {row?.taxesFees || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>Hull NIM Deductible</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">$ {row?.idHullNIMDeductible || 0}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>Hull IM Deductible</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">$ {row?.idHullIMDeductible || 0}</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                      <Table sx={{ mt: 2 }}>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">Amount</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>Total</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">$ {calculateTotal(row).toFixed(2)}</TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography component="div" variant="h5" sx={{ pl: 4 }}>
                          LienHolders
                        </Typography>
                        <Tooltip title="Add LienHolder" placement="top">
                          <span>
                            <IconButton
                              color="primary"
                              aria-label="Edit"
                              size="large"
                              sx={{ pr: 4 }}
                              onClick={handleLienHolderAdd}
                              disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                            >
                              <Add fontSize="large" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Typography>
                      <Box sx={{ overflowX: 'auto' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lienHolders.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>
                                  <Typography component="div" align="left" variant="subtitle1">
                                    {row?.LienHolder?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Delete LienHolder Relation" placement="top">
                                    <span>
                                      <IconButton
                                        color="secondary"
                                        aria-label="Edit"
                                        size="large"
                                        disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                                        onClick={() => handleDeleteRelationModal(row, 'LienHolder')}
                                      >
                                        <DeleteIcon fontSize="large" />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }}>
                <CardContent>
                  <Grid item xs={12}>
                    <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography component="div" variant="h5" sx={{ pl: 4 }}>
                        Pilots
                      </Typography>
                      <Tooltip title="Add Pilot" placement="top">
                        <span>
                          <IconButton
                            color="primary"
                            aria-label="Edit"
                            size="large"
                            sx={{ pr: 4 }}
                            onClick={handlePilotAdd}
                            disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                          >
                            <Add fontSize="large" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Typography>
                    <Box sx={{ overflowX: 'auto' }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Pilot Name</TableCell>
                            <TableCell align="right">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pilots.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>
                                <Typography component="div" align="left" variant="subtitle1">
                                  {`${row?.Pilot.firstname} ${row?.Pilot?.lastname}`}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Pilot Detial's">
                                  <IconButton color="primary" ara-label="delete" size="large" onClick={() => handleGoToPilotDetail(row)}>
                                    <DescriptionTwoToneIcon fontSize="large" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Pilot Relation" placement="top">
                                  <span>
                                    <IconButton
                                      color="secondary"
                                      aria-label="Edit"
                                      size="large"
                                      disabled={!auth.writePrivledge(auth.user, 'Aircraft_Adm')}
                                      onClick={() => handleDeleteRelationModal(row, 'Pilot')}
                                    >
                                      <DeleteIcon fontSize="large" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      <PilotDialog
        open={openPilot}
        handleClosedialog={handleClosedialog}
        handlePilotSave={handlePilotSave}
        actionType="Details"
        pilot={{}}
        customerOrg={customerOrg}
        customerOrgId={ids.idCustomerOrganization}
        pilotRelations={pilots}
      />
      <PolicyDialog
        open={openPolicy}
        handleClosedialog={handleClosedialog}
        handlePolicySave={handleSavePolicy}
        actionType="Details"
        customerOrg={customerOrg}
        policy={editAircraftPolicy}
        companyId={companyId}
        customerOrgId={ids.idCustomerOrganization}
        policyRelations={policies}
        isPolicyAircraft={true}
      />
      <LienholderDialog
        open={openLienholder}
        handleClosedialog={handleClosedialog}
        handleLienholderSave={handleSaveLienholder}
        actionType="Details"
        lienholderState={{}}
        lienholderRelations={lienHolders}
      />
    </>
  );
};

export default AircraftDetails;
