import { Document } from '@react-pdf/renderer';
import InsurancePolicyPDF from './InsurancePolicy';
import CoverPage from './CoverPage';
// import LiabilityCoverage from './LiabilityCoverage';
// import RenterPilotEndorsement from './RenterPilot';
import CoverageIdentificationPage from './CoverageIdentificationPage';
// import LienHolderInterestPDF from './LienHolderInterestPDF';
// import SpecialCoverPilotEndorsement from './SpecialCoverPilotEndorsement';
// import TurbineEndorsement from './TurbineEndorsement';
// import DamagedAircraft from './DamagedAircraft';
// import NonOwnershipEndorsement from './NonOwnershipEndorsement';
// import SpecialEndorsement from './SpecialEndorsement';
// import FloodStormDeductible from './FloodStormDetuctible';
// import HeliocopterEndorsement from './HeliocopterEndorsement';
// import TerrorismCoverage from './Terrorism';
// import FinancedEndorsement from './FinancedEndorsement';
// import LeasedAircraftEndorsement from './LeasedAircraftEndorsement';
// import SeaPlaneEndorsement from './SeaPlaneEndorsement';
// import WarConfiscationEndorsement from './WarConfiscation';
// import CanopyEndorsement from './CanopyEndorsement';
// import GearUpEndorsement from './GearUpEndorsement';
// import PassengerSettlementPDF from './PassengerSettlementPdf';
import PilotRequirementPDF from './PilotRequirementPDF';
import StateEndorsement from './StatesEndorsement';
import GeneralEndorsement from './GeneralEndorsement';
// const totalPages = {
//   coverPage: 1,
//   coverage: 2,
//   pilotRequirements: 1,
//   canopy: 1,
//   terrorism: 1,
//   gearUp: 1,
//   passenger: 2,
//   liability: 1,
//   capTerrorism: 1,
//   financedPrem: 1,
//   leased: 1,
//   seaplane: 1,
//   warConf: 1,
//   helio: 1,
//   specialUse: 1,
//   stormFlood: 1,
//   damaged: 1,
//   nonOwner: 1,
//   turbine: 1,
//   specialUseCoverage: 1,
//   lienholder: 2,
//   renter: 1,
//   insurancePolicy: 11
// }
const PDFContent = ({ policy }) => (
  <Document>
    <CoverPage />
    <CoverageIdentificationPage policy={policy}/>
    <PilotRequirementPDF requirements={policy?.pilotRequirementDescription} rental={true}/>
    <GeneralEndorsement />
    {/* <PassengerSettlementPDF />
    <CanopyEndorsement />
    <LiabilityCoverage type="" />
    <TerrorismCoverage />
    <GearUpEndorsement />
    <FinancedEndorsement />
    <LeasedAircraftEndorsement />
    <SeaPlaneEndorsement />
    <WarConfiscationEndorsement />
    <HeliocopterEndorsement />
    <SpecialEndorsement title="Aerial Photography I" />
    <FloodStormDeductible />
    <DamagedAircraft />
    <NonOwnershipEndorsement />
    <TurbineEndorsement />
    <SpecialCoverPilotEndorsement />
    <LienHolderInterestPDF state="New Hampshire" />
    <RenterPilotEndorsement /> */}
    <StateEndorsement state="Illinois" formNumber="SKWD GA 70 00 09 23" />
    <InsurancePolicyPDF />
  </Document>
);

export default PDFContent;
