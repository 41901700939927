import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

export const Page2 = () => (
  <Page size="letter" style={styles.page}>
    <View style={styles.content}>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Pilots and Use</Text>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Unless the requirements regarding Pilots in Item 9 of the Coverage Identification Page and Use (Item 10) are met;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Wear and Tear and Mechanical Breakdown</Text>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Caused by wear or tear, deterioration, freezing, mechanical or electrical breakdown or failure, including any loss or damage
                to a component or system of the <Text style={styles.boldText}>aircraft</Text> that is caused in whole or in part by a
                defective product or the negligence of any person or organization in repairing or installing such product.{' '}
                <Text style={styles.boldText}>We</Text> will, however, pay for direct physical damage to other systems of the{' '}
                <Text style={styles.boldText}>aircraft</Text> that results from these causes. For the purpose of this provision, damage that
                results from the breakdown, failure or malfunction of any internal or external engine component, or any accessory, component
                or part attached to the engine is a breakdown or failure of the entire engine;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Tires</Text>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Tires, unless caused by theft or vandalism, or the loss or damage is the result of other loss or damage{' '}
                <Text style={styles.boldText}>we</Text> cover;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Seaplane-Amphibian</Text>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If it is equipped for water takeoffs and landings unless the <Text style={styles.boldText}>aircraft</Text> is identified as
                a seaplane or amphibian in Item 5 of the Coverage Identification Page;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Undisclosed Ownership and Other Interests</Text>
              <Text style={styles.listItemHeading}>
                e.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If <Text style={styles.boldText}>your</Text> interest in the <Text style={styles.boldText}>aircraft</Text> is subject to any
                liens, mortgages, lease or ownership interest claims of <Text style={styles.boldText}>others</Text> unless all interests of{' '}
                <Text style={styles.boldText}>others</Text> are identified in either Item 1 or 11 of the Coverage Identification Page or in
                an endorsement;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>War-Confiscation</Text>
              <Text style={styles.listItemHeading}>
                f.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Caused by terrorist activities or arrest, restraint, seizure, confiscation, detention by or at the direction of any
                government; or caused by declared or undeclared war;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Radiation</Text>
              <Text style={styles.listItemHeading}>
                g.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Directly or indirectly caused by or arising out of ionizing radiation or contamination by radioactivity from any source; or
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Embezzlement, Conversion or Secretion</Text>
              <Text style={styles.listItemHeading}>
                h.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If anyone to whom <Text style={styles.boldText}>you</Text> relinquish possession of the{' '}
                <Text style={styles.boldText}>aircraft</Text> embezzles, converts or secretes the{' '}
                <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
          </View>
          <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
            <Text style={styles.boldText}>We</Text> also will not pay for depreciation, loss of use, loss of profits, loss of guaranty or
            warranty, or any other economic or consequential damage of any kind.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            5.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>You</Text> Must Do
          </Text>
          <Text style={styles.listItemParagraph}>
            If the <Text style={styles.boldText}>aircraft</Text> is damaged, you must:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Protect the Aircraft</Text>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Do all <Text style={styles.boldText}>you</Text> can to protect the <Text style={styles.boldText}>aircraft</Text> from
                further loss, and we will pay <Text style={styles.boldText}>you</Text> for all reasonable expenses incurred by{' '}
                <Text style={styles.boldText}>you</Text>
                in protecting it;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Proof of Loss</Text>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Give <Text style={styles.boldText}>us</Text> a sworn Proof of Loss statement within 90 days of the loss;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Show Us the Physical Damage</Text>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Show <Text style={styles.boldText}>us</Text> the physical damage to the <Text style={styles.boldText}>aircraft</Text> before
                repair or disposition;
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Show Us the Records</Text>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Show <Text style={styles.boldText}>us</Text> all records <Text style={styles.boldText}>you</Text> have that would prove the
                amount of loss.
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.listItem, {marginBottom: '-20px'}]}>
          <Text style={styles.listItemHeading}>
            6.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}When <Text style={styles.boldText}>We</Text> Will Pay
          </Text>
          <Text style={styles.listItemParagraph}>
            <Text style={styles.boldText}>We</Text> will pay for loss of or damage to the <Text style={styles.boldText}>aircraft</Text>{' '}
            covered by your policy:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Damaged Aircraft</Text>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                within 30 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                Proof of Loss statement and <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text> agree on
                the amount; or
              </Text>
            </View>
            <View>
              <Text style={{ marginLeft: 15, fontStyle: 'italic' }}>Destroyed Aircraft</Text>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                within 30 days after <Text style={styles.boldText}>you</Text> have given <Text style={styles.boldText}>us</Text> a sworn
                Proof of Loss statement and <Text style={styles.boldText}>you</Text> have delivered to{' '}
                <Text style={styles.boldText}>us</Text> the <Text style={styles.boldText}>aircraft</Text> log books, airworthiness
                certificate and a bill of sale transferring clear title to the <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    <PageFooter form={'S1WD GA 92 40 03 2V'} number={7} />
  </Page>
);
