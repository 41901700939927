import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { PageFooter } from '../../PageFooter';
import { styles } from '../../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

export const Page1 = () => (
  <Page size="letter" style={styles.page}>
    <View style={styles.header}>
      <Text>Part One. GENERAL PROVISIONS AND CONDITIONS</Text>
    </View>
    <View style={styles.contentDivider} />
    <View style={styles.content}>
      <View>
        <Text>
          Here are some items <Text style={styles.boldText}>you</Text> need to be aware of before <Text style={styles.boldText}>you</Text>{' '}
          read the other Parts of <Text style={styles.boldText}>your</Text> policy that help explain{' '}
          <Text style={styles.boldText}>your</Text> coverage.
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            1.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'} Words and Phrases
          </Text>
          <View style={styles.listItemParagraph}>
            <Text>The following words and phrases when appearing in bold face type have special meaning throughout the policy:</Text>
          </View>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>You</Text> and <Text style={styles.boldText}>your</Text> mean the person(s) or organization(s)
                named in Item 1 of the Coverage Identification Page under the heading “Named Insured.”
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Someone we protect</Text> means any person or organization, other than{' '}
                <Text style={styles.boldText}>you,</Text> we provide liability coverage through this policy.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Anyone</Text> or <Text style={styles.boldText}>otheres</Text> means any person or organization
                other than <Text style={styles.boldText}>you.</Text> The terms may include person(s) or organization(s){' '}
                <Text style={styles.boldText}>we</Text>
                define as <Text style={styles.boldText}>someone we protect.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>We, us</Text> or <Text style={styles.boldText}>our</Text> means the insurance company named on
                the Coverage Identification Page.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                e.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Aviation Managers</Text> means Acceleration Aviation Underwriters, Inc., which manages{' '}
                <Text style={styles.boldText}>our</Text> aviation insurance business for <Text style={styles.boldText}>us.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                f.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Aircraft</Text> means the aircraft owned or leased by <Text style={styles.boldText}>you</Text>{' '}
                that is shown in Item 5 of <Text style={styles.boldText}>your</Text> Coverage Identification Page or qualifying under PART
                FIVE of <Text style={styles.boldText}>your</Text> policy. It includes the airframe, landing gear system, engine (including
                propeller governor or other parts or accessories attached or mounted to the engine), propeller or rotor, flight and engine
                instruments, avionics, electrical system, flight control system, fuel system, and any hydraulic and pressurization system.
                Parts usually mounted or attached to the aircraft are included while temporarily removed so long as they are not replaced by
                other parts. Log books, documents or other records related to the ownership or maintenance of an aircraft are not a part of
                the <Text style={styles.boldText}>aaircraft.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                g.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Agreed value</Text> means the amount of money shown in Item 5 of the Coverage Identification
                Page. This is the amount <Text style={styles.boldText}>you</Text> and <Text style={styles.boldText}>we</Text>
                have agreed <Text style={styles.boldText}>your aircraft</Text> is worth and the maximum amount of Aircraft Physical Damage
                coverage <Text style={styles.boldText}>we</Text> provide.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                h.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>In motion</Text> means when any part of the <Text style={styles.boldText}>aircraft</Text> is
                moving as a result of electrical or engine power or the resulting momentum.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                i.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>In flight</Text> means when movement of the <Text style={styles.boldText}>aircraft</Text>{' '}
                begins for takeoff until the end of the landing run.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                j.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Passenger</Text> means any person who is in the <Text style={styles.boldText}>aircraft</Text>{' '}
                or getting in or out of it. If more than one of the <Text style={styles.boldText}>aircraft</Text> shown in Item 5 of your
                Coverage Identification Page or an endorsement to this policy is involved in one{' '}
                <Text style={styles.boldText}>occurance,</Text> every <Text style={styles.boldText}>person</Text> who is in any of the{' '}
                <Text style={styles.boldText}>aircraft</Text> is a <Text style={styles.boldText}>passenger.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                k.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Bodily Injury</Text> means physical injury to a person, including sickness, disease (including
                mental anguish) or death resulting from such physical injury.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                l.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Property damage</Text> means physical damage to or destruction of tangible property, including
                any resulting loss of use of that property. It does not include damage to or destruction of the{' '}
                <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                m.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Accident</Text> means a sudden event during the policy period, neither expected nor intended
                by you or <Text style={styles.boldText}>someone we protect,</Text> that involves the{' '}
                <Text style={styles.boldText}>aircraft</Text> and causes physical damage to or loss of the{' '}
                <Text style={styles.boldText}>aircraft.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                n.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Occurrence</Text> means a sudden event or repeated exposure to conditions, during the policy
                period, neither expected nor intended by <Text style={styles.boldText}>you</Text> or{' '}
                <Text style={styles.boldText}>someone we protect,</Text> involving the <Text style={styles.boldText}>aircraft</Text> that
                causes <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> to others
                during the policy period. All <Text style={styles.boldText}>bodily injury</Text> or{' '}
                <Text style={styles.boldText}>property damage</Text> resulting from the same general conditions will be considered to be
                caused by one <Text style={styles.boldText}>occurance.</Text> If more than one of the{' '}
                <Text style={styles.boldText}>aircraft</Text> shown in Item 5 of <Text style={styles.boldText}>your</Text> Coverage
                Identification Page is involved in the same <Text style={styles.boldText}>occurance,</Text> all{' '}
                <Text style={styles.boldText}>bodily injury</Text> or <Text style={styles.boldText}>property damage</Text> resulting from
                the involvement of all of the <Text style={styles.boldText}>aircraft</Text> will be considered to be caused by one{' '}
                <Text style={styles.boldText}>occurance.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                o.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Federal Aviation Administration (FAA)</Text> means the authority of the United States of
                America having jurisdiction over civil aviation or its counterpart in another country.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                p.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Student Pilot</Text> means any pilot holding a valid student pilot certificate issued by the{' '}
                <Text style={styles.boldText}>FAA</Text> or a person receiving flight instruction from an{' '}
                <Text style={styles.boldText}>FAA</Text> certificated flight instructor.
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                q.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Renter Pilot</Text> means any person or organization who is renting the{' '}
                <Text style={styles.boldText}>aircraft</Text> from <Text style={styles.boldText}>you,</Text> including{' '}
                <Text style={styles.boldText}>student pilots.</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                r.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                <Text style={styles.boldText}>Pleasure and Business</Text> means use of the <Text style={styles.boldText}>aircraft</Text> by{' '}
                <Text style={styles.boldText}>you</Text> or by <Text style={styles.boldText}>someone we protect</Text> for personal and
                business related purposes where no charge is made for such use. <Text style={styles.boldText}>You</Text> or{' '}
                <Text style={styles.boldText}>someone we protect</Text> may receive reimbursement for expenses
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
    <PageFooter form={'S1WD GA 92 40 03 2V'} number={3} />
  </Page>
);
