import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import { PageFooter } from '../PageFooter';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    fontSize: 10,
    marginBottom: 2
  },
  contentDivider: {
    borderTop: '1px solid black'
  },
  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 5
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  tableOfContentsContainer: {
    display: 'flex',
    flexDirection: 'row', // Two columns
    justifyContent: 'space-between',
    fontSize: 8.5
  },
  column: {
    width: '45%',
    gap: 10 // Adjust based on space between columns
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline'
  },
  leftText: {
    float: 'left'
  },
  rightText: {
    fontSize: 8.5,
    float: 'right'
  },
  dots: {
    flex: 1,
    top: '-4px',
    borderBottom: '1px dotted #333'
  },
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' }
  ]
});

export const TableOfContents = () => (
  <Page size="letter" style={styles.page}>
    <View style={styles.header}>
      <Text style={styles.boldText}>WHERE TO FIND</Text>
      <View style={styles.contentDivider} />
    </View>
    <View style={styles.tableOfContentsContainer}>
      <View style={styles.column}>
        {/* Row 1 in Column 1 */}
        <View>
          <View style={styles.row}>
            <Text style={[styles.leftText, styles.boldText]}>Validation</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Cover Page</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Coverage Indentification Page</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Your Insurance Company’s Name</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Top Left</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Your Policy Number</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Top Right</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Prior Policy Number </Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Top Right</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Your Agent’s Name and Address</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Top Right</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Named Insured</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 1</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Your Address</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 2</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Policy Period</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 3</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Location of Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 4</Text>
          </View>
          <Text style={styles.leftText}>Description of Aircraft and</Text>
          <View style={styles.row}>
            <Text style={styles.leftText}>Physical Damage Coverage</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 5</Text>
          </View>
          <Text style={styles.leftText}>Liability and Medical Payments Coverage</Text>
          <View style={styles.row}>
            <Text style={styles.leftText}>and Limits of Coverage.</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 6</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Premiums</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 7</Text>
          </View>
          <Text style={styles.leftText}>Forms and Endorsements Attached when</Text>
          <View style={styles.row}>
            <Text style={styles.leftText}>the Policy is Issued</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 8</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Requirements for the Pilot Flying the Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 9</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>The Use of the Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 10</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Additional Interests</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Item 11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>Signature of our Aviation Managers</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Bottom</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Part One. General Provisions and Conditions</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftText}>1.&ensp;Words and Phrases</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Page 3</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>2.&ensp;Our Obligations and Your Duties</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>4</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>3.&ensp;Requirements for the Pilot Flying the Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>4</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>4.&ensp;The Use of the Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>4</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>5.&ensp;When and Where You Are Covered</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>4</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>6.&ensp;If there is an Accident or Occurrence</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>4</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>7.&ensp;Changing the Policy</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>8.&ensp;Canceling the Policy</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>9.&ensp;Other Coverage</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>10.&ensp;Transfer of Interest in Your Policy</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>11.&ensp;Our Right of Recovery</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>12.&ensp;State Statutes</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>13.&ensp;Legal Action Against Us</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>5</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>14.&ensp;Concealment or Misrepresentation</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>6</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>15.&ensp; Inspection and Audit</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>6</Text>
          </View>
        </View>
        {/* Add more rows to this column */}
      </View>
      <View style={styles.column}>
        <View>
          <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Part Two. Aircraft Physical Damage Coverage</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftText}>1.&ensp; What We Cover</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Page 6</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>2.&ensp; What You Must Pay or Bear (Deductible)</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>6</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>3.&ensp; What We Will Pay (Less Deductible)</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>6</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>4.&ensp; What We Will Not Pay</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>7</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>5.&ensp; What You Must Do</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>7</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>6.&ensp; When We Will Pay</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>8</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>7.&ensp; Theft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>8</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>8.&ensp; Disappearance</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>8</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>9.&ensp; Reinstatement of Coverage</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>8</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Part Three. Liability to Others</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftText}>1.&ensp; What We Cover</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Page 8</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>2.&ensp; Who Is Protected</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>8</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>3.&ensp; Who Is Not Protected</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>9</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>4.&ensp; What Is Not Covered</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>9</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>5.&ensp; Additional Protection</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>10</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Part Four. Medical Expense Coverage</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftText}>1.&ensp; What We Will Pay</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Page 10</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>2.&ensp; Whom We Will Pay</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>10</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>3.&ensp; What We Will Not Pay</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>10</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>4.&ensp; Effect of Payment</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>5.&ensp; Proof of Claim</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>6.&ensp; Legal Action for Medical Expenses</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Part Five. Special Provisions and Conditions</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftText}>1.&ensp; Newly Acquired Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>Page 11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>2.&ensp; Temporary Use of Substitute Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>3.&ensp; Use of Another Aircraft</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>4.&ensp; What Coverage We Will Provide</Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftText}>5.&ensp; What We Will Not Cover </Text>
            <Text style={styles.dots}></Text>
            <Text style={styles.rightText}>11</Text>
          </View>
        </View>
        <View>
        <View style={styles.row}>
            <Text style={[styles.boldText, { fontSize: 10 }]}>Endorsements</Text>
          </View>
        </View>
      </View>
    </View>
    <PageFooter form={'S1WD GA 92 40 03 2V'} number={2} />
  </Page>
);
