import { Page, View, Text, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';
import { PageFooter } from '../PageFooter';
import { styles } from '../Styles';

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

export const Part5PDF = () => (
  <Page size="letter" style={styles.page}>
    <View style={styles.header}>
      <Text>Part Five. SPECIAL PROVISIONS AND CONDITIONS</Text>
    </View>
    <View style={styles.contentDivider} />
    <View style={styles.content}>
      <View>
        <Text>
          These Special Provisions and Conditions do not apply unless Item 10 of the Coverage Identification Page states that the use of the
          <Text style={styles.boldText}>aircraft</Text> is limited to <Text style={styles.boldText}>pleasure and business</Text> use only.
        </Text>
      </View>
      <View style={styles.listContainer}>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            1.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Newly Acquired Aircraft
          </Text>
          <Text style={styles.listItemParagraph}>
            If <Text style={styles.boldText}>you</Text> notify <Text style={styles.boldText}>us</Text> during the policy period and within
            30 days after <Text style={styles.boldText}>you</Text> acquire ownership of another{' '}
            <Text style={styles.boldText}>aircraft,</Text> and pay the additional premium, <Text style={styles.boldText}>we</Text> will
            extend the coverage of this policy to that <Text style={styles.boldText}>aircraft</Text> if{' '}
            <Text style={styles.boldText}>we</Text> insure all of the <Text style={styles.boldText}>aircraft you</Text> own.
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            2.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Temporary Use of Substitute Aircraft
          </Text>
          <Text style={styles.listItemParagraph}>
            If <Text style={styles.boldText}>you</Text> are unable to fly the <Text style={styles.boldText}>aircraft</Text> because of its
            breakdown, repair, servicing, loss or destruction, <Text style={styles.boldText}>we</Text> will extend the coverage of this
            policy to <Text style={styles.boldText}>your</Text> use of a substitute<Text style={styles.boldText}>aircraft.</Text>
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            3.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}Use of Another <Text style={styles.boldText}>Aircraft</Text>
          </Text>
          <Text style={styles.listItemParagraph}>
            If <Text style={styles.boldText}>you</Text> are one individual or one individual and spouse (including an individual recognized
            as a partner in a domestic partnership under applicable state law) and use another <Text style={styles.boldText}>aircraft</Text>{' '}
            not owned in whole or in part by <Text style={styles.boldText}>you</Text> or furnished for{' '}
            <Text style={styles.boldText}>your</Text>
            regular use, <Text style={styles.boldText}>we</Text> will extend the coverage of this policy to{' '}
            <Text style={styles.boldText}>your</Text> use of another <Text style={styles.boldText}>aircraft.</Text>
          </Text>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            4.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What Coverage <Text style={styles.boldText}>We</Text> Will Provide
          </Text>
          <Text style={styles.listItemParagraph}>The coverage provided under this PART FIVE will be:</Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                The same Liability to Others (PART THREE) coverage and Medical Expense (PART FOUR) coverage{' '}
                <Text style={styles.boldText}>we</Text> provide for an <Text style={styles.boldText}>aircraft</Text> with the greatest
                seating capacity as shown on <Text style={styles.boldText}>your</Text> Coverage Identification Page; and
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                On Newly Acquired Aircraft, the same Aircraft Physical Damage (PART TWO) coverage and deductible amounts as{' '}
                <Text style={styles.boldText}>we</Text> provide on similar category and class <Text style={styles.boldText}>aircraft</Text>{' '}
                with the highest <Text style={styles.boldText}>agreed value</Text> shown on <Text style={styles.boldText}>your</Text>{' '}
                Coverage Identification Page. Subject to the foregoing coverage limit, the maximum <Text style={styles.boldText}>we</Text>{' '}
                will pay for physical damage or loss is the amount <Text style={styles.boldText}>you</Text> paid for the{' '}
                <Text style={styles.boldText}>aircraft,</Text> plus the cost of any repairs or additions you made; or in the case of a
                trade-in, the fair market value of the aircraft plus the cost of any repairs or additions{' '}
                <Text style={styles.boldText}>you</Text> made.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.listItem}>
          <Text style={styles.listItemHeading}>
            5.{'\u00A0'}
            {'\u00A0'}
            {'\u00A0'}What <Text style={styles.boldText}>We</Text> Will Not Cover
          </Text>
          <Text style={styles.listItemParagraph}>
            In addition to those persons and things which <Text style={styles.boldText}>we</Text> will not cover, protect or pay in other
            parts of <Text style={styles.boldText}>your</Text> policy, <Text style={styles.boldText}>we</Text> will not provide coverage for
            Temporary Use of Substitute Aircraft, Use of Another Aircraft or Newly Acquired Aircraft:
          </Text>
          <View style={styles.nestedListContainer}>
            <View>
              <Text style={styles.listItemHeading}>
                a.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Unless the requirements of the Coverage Identification Page regarding Pilots (Item 9) and Use (Item 10) are met;
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                b.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                Unless it is licensed under a standard airworthiness certificate issued by the <Text style={styles.boldText}>FAA;</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                c.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If it is a multiengine <Text style={styles.boldText}>aircraft</Text> unless an <Text style={styles.boldText}>aircraft</Text>{' '}
                in Item 5 on the Coverage Identification Page is a multiengine <Text style={styles.boldText}>aircraft;</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                d.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If it is a turbine powered aircraft unless an <Text style={styles.boldText}>aircraft</Text> in Item 5 on the Coverage
                Identification Page is a turbine powered
                <Text style={styles.boldText}>aircraft;</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                e.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If it is a rotorcraft unless an <Text style={styles.boldText}>aircraft</Text> in Item 5 on the Coverage Identification Page
                is a rotorcraft; or
              </Text>
            </View>
            <View>
              <Text style={styles.listItemHeading}>
                f.{'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
              </Text>
              <Text style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                If it is a seaplane or amphibian unless an <Text style={styles.boldText}>aircraft</Text> in Item 5 on the Coverage
                Identification Page is a seaplane or amphibian.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.boldText, { display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 10 }]}>
        <Text>ADDITIONAL POLICY TERMS, PROVISIONS</Text>
        <Text>AND CONDITIONS APPLICABLE TO YOUR COVERAGE MAY BE ADDED BY ENDORSEMENT.</Text>
      </View>
    </View>
    <PageFooter form={'S1WD GA 92 40 03 2V'} number={11} />
  </Page>
);
