import { Page, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { FooterPDF } from 'views/PDFComponents/Footer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';
import OpenSansBold from 'assets/Fonts/OpenSans-Bold-webfont.ttf';
import OpenSansItalic from 'assets/Fonts/OpenSans-Italic-webfont.ttf';
import OpenSansBoldItalic from 'assets/Fonts/OpenSans-BoldItalic-webfont.ttf';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    marginBottom: 10
  },
  content: {
    fontSize: 8.5,
    gap: 10
  },

  centeredText: {
    textAlign: 'center',
    fontSize: 8.5
  },

  page: {
    padding: '40px 50px',
    fontFamily: 'OpenSans',
    position: 'relative',
    fontSize: 12,
    lineHeight: 1.5,
    gap: 10
  },

  boldText: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold'
  },
  listContainer: {
    fontSize: 8,
    paddingLeft: 10
  },
  listItem: {
    gap: 5,
    marginBottom: '5px'
  },
  listItemHeading: {
    fontFamily: 'OpenSans',
    flexDirection: 'row',
    marginBottom: 0
  },
  listItemParagraph: {
    fontFamily: 'OpenSans',
    marginLeft: 15
  },
  itemParagraph: {
    marginTop: '0px'
  },
  nestedListContainer: {
    paddingLeft: 15,
    gap: 10 // Indent nested items
  },
  nestedListItemParagraph: {
    marginLeft: 15,
    marginTop: '-11px'
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' }, // Regular font
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic', fontWeight: 'normal' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
});

const IdahoEndorsement = () => (
  <>
    <Page size="LETTER" style={styles.page}>
      <View style={[styles.header, { fontSize: 8 }]}>
        <Text>THIS ENDORSEMENT CHANGES YOUR POLICY. PLEASE READ IT CARFULLY.</Text>
      </View>
      <View style={styles.header}>
        <Text style={styles.boldText}>Idaho Change Endorsement</Text>
      </View>
      <View style={styles.content}>
        <View>
          <Text>
            This endorsement adds the following to <Text style={styles.boldText}>Part One. GENERAL PROVISIONS AND CONDITIONS,</Text>{' '}
            Paragraph 8, Canceling the Policy, to read as follows.
          </Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeading}>8. &nbsp; &nbsp; &nbsp;</Text>
            <View style={[styles.listItemParagraph, { marginTop: '-16px' }]}>
              <Text>Canceling the Policy</Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>You</Text> may cancel <Text style={styles.boldText}>your</Text> policy at any time by telling{' '}
                <Text style={styles.boldText}>us</Text> in writing in advance, through{' '}
                <Text style={styles.boldText}>our Aviation Managers,</Text> of the date <Text style={styles.boldText}>you</Text>
                want <Text style={styles.boldText}>your</Text> coverage to end. If <Text style={styles.boldText}>you</Text> cancel the
                policy, <Text style={styles.boldText}>we</Text> will return 90% of the unearned premium{' '}
                <Text style={styles.boldText}>you</Text> have paid.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> or <Text style={styles.boldText}>our Aviation Managers</Text> can cancel this policy
                as follows:
              </Text>
            </View>
            <View style={styles.nestedListContainer}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    The policy can be canceled at any tome for nonpayment of premium by mailing or delivering a notice of cancellation to
                    the first Named Insured at least 10 days before the effective date of the cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect less than 60 days and is not a renewal, <Text style={styles.boldText}>we</Text> may
                    cancel at any time by mailing or delivering a notice of cancellation to the first Named Insured at least 30 days before
                    the effective date of cancellation; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>c. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    If the policy has been in effect 60 days or is a renewal <Text style={styles.boldText}>we</Text> may cancel by mailing
                    or delivering a notice of cancellation to the first Named Insured at least 30 days before the effective date of
                    cancellation and only for one of the following reasons:
                  </Text>
                </View>
                <View style={[styles.nestedListContainer, { gap: 1 }]}>
                  <View>
                    <Text style={styles.listItemHeading}>(1) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Fraud or material misrepresentation made by <Text style={styles.boldText}>you</Text> or with your knowledge in
                        otaining the policy, continuing the policy or in presenting a claim under the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(2) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Activities or omissions on <Text style={styles.boldText}>your</Text> part which increase any hazard insured against,
                        including a failure to comply with loss control recommendations;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(3) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Change in the risk which materially increases the risk of loss after insurance coverage has been issued or renewed
                        including, but not limited to, an increase in exposure due to regulation or court decision;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(4) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Loss or decrease of <Text style={styles.boldText}>our</Text> reinsurance covering all or part of the risk or
                        exposure by the policy;
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(5) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Determination by the Director of Insurance of the State of Idaho that the continuation of the policy would
                        jeopardize <Text style={styles.boldText}>our</Text> solvency or would place <Text style={styles.boldText}>us</Text>{' '}
                        in violation of the insurance laws of the State of Idaho or any other state; or
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.listItemHeading}>(6) &nbsp; &nbsp; &nbsp;</Text>
                    <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                      <Text>
                        Violation or breach by <Text style={styles.boldText}>you</Text> of any policy terms or conditions other than
                        nonpayment of premium
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                Proof of mailing or delivery of the notice is sufficient proof of notice. <Text style={styles.boldText}>We</Text> will
                compute the premium earned bu <Text style={styles.boldText}>us</Text> based on the percentage of the original policy period
                that <Text style={styles.boldText}>we</Text> provided coverage.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                <Text style={styles.boldText}>We</Text> will return <Text style={styles.boldText}>you</Text> any premium that{' '}
                <Text style={styles.boldText}>you</Text> have paid that <Text style={styles.boldText}>we</Text> have not earned, but making
                the refund is not a condition of cancellation.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                However, if <Text style={styles.boldText}>we</Text> pay or have paid the <Text style={styles.boldText}>agreed value</Text>{' '}
                of the <Text style={styles.boldText}>aircraft,</Text> less any applicable deductible under the Aircraft Physical Damage
                Coverage, <Text style={styles.boldText}>we</Text> are entitled to the total Aircraft Physical Damage premium shown on the
                Coverage Identification Page for the <Text style={styles.boldText}>aircraft</Text> on which{' '}
                <Text style={styles.boldText}>we</Text> made or make payment. <Text style={styles.boldText}>You</Text> agree to pay any
                premium that may be due or permit <Text style={styles.boldText}>us</Text> to deduct such premium from{' '}
                <Text style={styles.boldText}>our</Text> loss payment.
              </Text>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> decide not to renew this policy <Text style={styles.boldText}>we</Text> will mail
                or deliver written notice of non-renewal to the first Named Insured at leasst 45 days prior to the expiration of the policy.
                Notice of non-renewal is not required if:
              </Text>
            </View>
            <View style={[styles.nestedListContainer, { gap: 1 }]}>
              <View>
                <Text style={styles.listItemHeading}>a. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    <Text style={styles.boldText}>We</Text> or a company within the same insurance group has offered to issue a renewal
                    policyl; or
                  </Text>
                </View>
              </View>
              <View>
                <Text style={styles.listItemHeading}>b. &nbsp; &nbsp; &nbsp;</Text>
                <View style={[styles.nestedListItemParagraph, styles.listItemParagraph]}>
                  <Text>
                    Where the Named Insured has obtained replacement coverage or has agreed in writing to obtain replacement coverage.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.listItemParagraph}>
              <Text>
                If <Text style={styles.boldText}>we</Text> make a premium or coverage change <Text style={styles.boldText}>we</Text> will
                mail or deliver a written notice to the first Named Insured at least 45 days prior to the expiration of the policy. If{' '}
                <Text style={styles.boldText}>we</Text> fail to provide such 45 days notice, the coverage provided to{' '}
                <Text style={styles.boldText}>you</Text> will remain in effect until 45 days after such notice is given or until the
                effective date of the replacement coverage obtained by <Text style={styles.boldText}>you,</Text>
                whichever first occurs. Any additional premium shall be calculated pro rate as of the current or previous year`s premium.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <FooterPDF page={1} endPage={2} />
    </Page>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.content}>
        <View style={styles.listContainer}>
          <View style={styles.listItem}>
            <View style={styles.listItemParagraph}>
              <Text>
                Any notice will be mailed or delivered to the first Named Insured`s last mailing address known to{' '}
                <Text style={styles.boldText}>us.</Text> If notice is mailed, proof of mailing will be sufficient proof of notice.
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.boldText}>DEPARTMENT OF INSURANCE CONTACT INFORMATION</Text>
        </View>
        <View>
          <Text>
            If <Text style={styles.boldText}>you</Text> have a concern or complaint regarding <Text style={styles.boldText}>your</Text>{' '}
            policy that cannot be resolved by contacting <Text style={styles.boldText}>us, you</Text> may contact the Idaho Department of
            Insurance at:
          </Text>
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: 1 }}>
          <Text>Idaho Department of Insurance</Text>
          <Text>Consumer Affairs</Text>
          <Text>700 W State Street, 3rd Floor</Text>
          <Text>PO Box 83720</Text>
          <Text>Boise, ID 83720-0043</Text>
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <Text>1-800-721-3272 or 208-334-4250 or www.DOI.Idaho.gov</Text>
        </View>
      </View>
      <FooterPDF sub={true} page={2} endPage={2} />
    </Page>
  </>
);

export default IdahoEndorsement;
