import { CoverPDF } from './Cover';
import { TableOfContents } from './TableOfContents';
import { Part1PDF } from './Part1';
import { Part2PDF } from './Part2';
import { Part3PDF } from './Part3';
import { Part4PDF } from './Part4';
import { Part5PDF } from './Part5';
const InsurancePolicyPDF = () => {
  return (
    <>
      <CoverPDF />
      <TableOfContents />
      <Part1PDF />
      <Part2PDF />
      <Part3PDF />
      <Part4PDF />
      <Part5PDF />
    </>
  );
};

export default InsurancePolicyPDF;
