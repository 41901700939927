import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import OpenSansRegular from 'assets/Fonts/OpenSans-Regular-webfont.ttf';

const styles = StyleSheet.create({
  footerContainer: {
    fontFamily: 'OpenSans',
    position: 'absolute',
    width: '100%',
    marginLeft: '50px',
    bottom: 20,
    paddingHorizontal: 10,
    borderTopWidth: 1,
    borderTopColor: '#000'
  },
  footerSubContainer: {
    fontFamily: 'OpenSans',
    fontSize: 6,
    position: 'absolute',
    width: '100%',
    marginLeft: '50px',
    bottom: 20
  },
  footerText: {
    fontSize: 6,
    marginBottom: 3
  },
  footerEndContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 5
  },
  footerEndLeft: {
    fontSize: 6
  },
  footerEndRight: {
    fontSize: 6
  }
});

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: OpenSansRegular, fontWeight: 'normal' } // Regular font
  ]
});

export const FooterPDF = ({ sub = false, form="SKWD GA 12 40 09 23", page, endPage }) => {
  return (
    <View style={!sub ? styles.footerContainer : styles.footerSubContainer}>
      {!sub && (
        <>
          <Text style={[{ textAlign: 'center', marginBottom: 5, marginTop: 5 }, styles.footerText]}>
            The following information is required only when the effective date of this endorsement is subsequent to the Policy effective
            date.
          </Text>
          <Text style={styles.footerText}>This endorsement is Endorsement No.</Text>
          <Text style={styles.footerText}>This endorsement is effective on</Text>
          <Text style={styles.footerText}>Attached to and forming a part of Policy No.</Text>
          <Text style={styles.footerText}>Issued to (First Named Insured):</Text>
          <Text style={styles.footerText}>Additional Premium:</Text>
        </>
      )}

      {/* Footer end with left and right text */}
      <View style={styles.footerEndContainer}>
        <Text style={styles.footerEndLeft}>
          Form <Text style={{ fontSize: 8 }}>{form}</Text>
        </Text>
        <Text style={styles.footerEndRight}>
          Page {page} of {endPage}
        </Text>
      </View>

      {!sub && <Text style={styles.footerEndLeft}>Issued:</Text>}
    </View>
  );
};
